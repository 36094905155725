export const generateNumberOptions = (start, end) => {  // Generate the numbers so show in the dropdown 
    const ages = [];
    for (let i = start; i <= end; i++) {
        ages.push({ label: i, value: i });
    }
    return ages;
}


export const resetState = (currentState) => {  // Resets the state
    const demoState = {};
    for (const key in currentState) {
        if (currentState.hasOwnProperty(key)) {
            demoState[key] = null
        }
    }
    return demoState;
};