import axios from "axios";
import { toast } from "react-toastify";
export const noAuth = async ({ method, url, data = {}, options = {} }) => {
    try {
        const res = await axios({
            method,
            url: `${process.env.REACT_APP_API_URL}${url}`,
            data,
            headers: {
                "Content-Type": "application/json"
            },
            ...options
        });

        if (!res?.data?.success) {
            throw new Error(res?.data?.message);
        }

        return res.data;
    }
    catch (err) {
        toast.error(err?.response?.data?.message || err?.message || "Something went wrong!");
    }
};


export const auth = async ({ method, url, data = {}, options = {} }) => {
    try {
        let token = localStorage?.getItem("adminSession");
        if(!token){
            throw new Error('Please login to continue');
        }
        const res = await axios({
            method,
            url: `${process.env.REACT_APP_API_URL}${url}`,
            data,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`
            },
            ...options
        });
        //storing new token
        if (res.headers['x-new-access-token']) {
            localStorage.setItem('adminSession', res.headers['x-new-access-token']);
            token = res.headers['x-new-access-token'];
        }

        if (!res?.data?.success) {
            throw new Error(res?.data?.message);
        }

        return res.data;
    }
    catch (err) {
            if(err?.response?.data?.errno === 403 && err?.response?.data?.type === 'ACCOUNT_DEACTIVATED'){
                localStorage.removeItem('adminSession');
                window.location.href = "/login";
            }
            if (err?.response?.data?.message === 'Please login to continue') {
                    localStorage.removeItem('adminSession'); 
                    // Redirect to login page
                    window.location.href = "/login";
            }
            if (err?.response?.data?.errno === 403 && err?.response?.data?.type === "TOKENEXPIRED") {
                // Handle expired refresh token, navigate to login page
                // Clear expired token
                localStorage.removeItem('adminSession'); 
                 // Redirect to login page
                window.location.href = "/login";
            } else {
                toast.error(err?.response?.data?.message || err?.message || "Something went wrong!");
                return err;
            }
    }
};