import React from "react"
import { HOTSPOT } from "common/routes.js"
import { ManagementSecMainHeader, Table } from "components"
import { getHotspotByStatus, updateHotspotStatus } from 'apis/hotspot'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { encodeToBase64 } from 'utils/encodeAndDecode.js'



function Index() {

    const navigate = useNavigate()
    const [hotspot, setHotspot] = useState()
    const [status, setStatus] = useState('active')
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Hotspot', accessor: 'hotspot' },
        {
            Header: 'Date',
            accessor: (data) => moment(data.start_date).format('YYYY-MM-DD')
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <>
                    {row?.original?.status === 'active' ?
                        <span onClick={() => handleUpdateStatus(row, 'inactive')} className='p-2 text-green-500 bg-green-100 cursor-pointer rounded-3xl'>
                            Active
                        </span>
                        :
                        <span onClick={() => handleUpdateStatus(row, 'active')} className='p-2 text-red-500 bg-red-100 cursor-pointer rounded-3xl'>
                            In Active
                        </span>

                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>Edit</button>
                </div>
            )
        }
    ];

    const handleBtn = async () => {
        navigate(HOTSPOT.CREATE_HOTSPOT)
    }

    const fetchOffersByStatus = async (page) => {
        const res = await getHotspotByStatus(page, status)
        setHotspot(res?.data)
        const pagination = res?.pagination
        setPageNation({ currentPage: pagination?.current_page, nextPage: pagination?.next_page, prevPage: pagination?.previous_page })
    }

    const handleUpdateStatus = async (row, value) => {
        const data = {
            status: value
        }
        const res = await updateHotspotStatus(row?.original?.id, data)
        if (res?.success) fetchOffersByStatus(pageNation?.currentPage)
    }

    const handleEdit = (row) => {
        const id = encodeToBase64(row.original.id);
        const url = `${HOTSPOT.CREATE_HOTSPOT}?id=${id}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };


    useEffect(() => {
        fetchOffersByStatus(1)
    }, [status])



    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Hotspot"}
                        subHeading={"Manage your events effectively"}
                        btnText={"Add Hotspot"}
                        handleBtn={handleBtn} />
                </div>
                {/* Table */}

                <div className="p-8">
                    <div className="flex">
                        <button onClick={() => setStatus('active')} className={`p-2 px-6 border ${status === 'active' ? 'bg-gray-300' : ''}`}>Active</button>
                        <button onClick={() => setStatus('inactive')} className={`p-2 px-6 border-2 border-b-0 ${status === 'active' ? '' : 'bg-gray-300'}`}>In Active</button>
                    </div>

                    {hotspot &&
                        <Table columns={columns} data={hotspot} pageNation={pageNation} handlePageNation={(value) => fetchAllUsers(value)} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Index