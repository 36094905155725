import { DropDown, InputField, TextEditor, ToggleBtn } from "components";
import React, { memo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import img from "../../../../../assets/createNewEvent/images/Picture.png";
import { eventSlugGeneration, fifthPage } from '../../../../../utils/validations/event';
import AdvancedImageCropping from 'components/NEW/image/imageCropping';
import { getSlug } from 'events';
import { convert12HourTo24Hour, convert24HourTo12Hour } from 'shared/dates';
import { formatDateToDisplayDateField } from '../../../../../utils/dataConversion';


function Index({ prevPage, nextPage, data, edit = false }) {
    const [slugSuggestionBtnVisibility, setSlugSuggestionBtnVisibility] = useState(true)
    const [eventDescription, setEventDescription] = useState(data?.description || '')
    const [eventData, setEventData] = useState({
        title: data?.title || null,
        description: data?.description || null,
        image: data?.image || null,
        start_date: data?.start_date || null,
        end_date: data?.end_date || null,
        start_time: data?.start_time || null,
        end_time: data?.end_time || null,
        isFree: data?.isFree || false,
        price: data?.price || 0,
        max_coin_redeemable: data?.max_coin_redeemable || 0,
        price_for_couple: data?.price_for_couple || false,
        total_slots: data?.total_slots || null,
        gender_based_slots: data?.gender_based_slots || false,
        slots_for_men: data?.slots_for_men || null,
        slots_for_female: data?.slots_for_female || null,

        prebooking_enabled: data?.prebooking_enabled || false,
        prebooking_from: data?.prebooking_from || null,
        prebooking_to: data?.prebooking_to || null,
        prebooking_slots: data?.prebooking_slots || null,

        enable_comments: data?.enable_comments || false,
        display_attendees: data?.display_attendees || false,
        featured: data?.featured || false,
        slug: data?.slug || null,
        meta_title: data?.meta_title || null,
        meta_description: data?.meta_description || null,
        publish_status: data?.publish_status || null,
        lattitude: data?.lattitude || null,
        longitude: data?.longitude || null,
        google_map_url: data?.google_map_url
    })

    const [advanceCropping, setAdvanceCropping] = useState(false)
    const [imgUrl, setImgUrl] = useState(data?.image || null)
    const [readOnly, setReadOnly] = useState(edit)
    const [updatePublishStatus, setUpdatePublishStatus] = useState({
        popUp: false,
        publish_status: null
    })

    const handleNextPage = async () => {
        try {
            const eventDataWithDescription = {
                ...eventData,
                description: eventDescription
            }
            await fifthPage.validate(eventDataWithDescription, { abortEarly: false });
            nextPage(eventDataWithDescription, 5);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const handlePrevPage = () => {
        prevPage(eventData, 4);
    }

    const updateState = (key, value) => {
        if (key === 'start_time' || key === 'end_time') {
            value = convert24HourTo12Hour(value)
        }
        setEventData(prev => ({ ...prev, [key]: value }))
    }


    // Image Section
    const fileInputRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateState("image", file);
                setImgUrl(reader.result);
                setAdvanceCropping(!advanceCropping)
            };
            reader.readAsDataURL(file);
        } else {
            updateState("image", null);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const price_for_couple = [
        { label: "Couples", value: "couple" },
        { label: "Person", value: "person" }
    ]

    const handleCropComplete = async (croppedFile) => {
        updateState('image', croppedFile);
        setImgUrl(URL.createObjectURL(croppedFile));
        setAdvanceCropping(!advanceCropping)
    }

    const handleEdit = async () => {
        try {
            setReadOnly(!readOnly)
            const eventDataWithDescription = {
                ...eventData,
                description: eventDescription
            }
            if (!readOnly) {
                nextPage(eventDataWithDescription);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const generateSlug = async () => {
        try {
            const storedEventData = JSON.parse(localStorage.getItem('createEventData'));
            const eventInfo = {
                start_date: eventData?.start_date,
                title: eventData?.title,
                category: storedEventData?.categories[0],
            }
            await eventSlugGeneration.validate(eventInfo, { abortEarly: false });
            const res = await getSlug(eventInfo)
            if (res?.success) {
                updateState('slug', res?.data)
                setSlugSuggestionBtnVisibility(false)
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    return (
        <div>
            {updatePublishStatus.popUp &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="p-4 bg-white rounded-md shadow-md">
                        <p className="mb-4">Are you sure you want to proceed?</p>
                        <div className="flex items-center justify-center">
                            <button className="px-4 py-2 mr-2 bg-gray-300 rounded-md hover:bg-gray-400" onClick={() => setUpdatePublishStatus(prev => ({ ...prev, popUp: false }))}>Cancel</button>
                            <button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600" onClick={() => {
                                setUpdatePublishStatus(prev => ({ ...prev, popUp: false }))
                                updateState("publish_status", updatePublishStatus.publish_status)
                            }}>Confirm</button>
                        </div>
                    </div>
                </div>
            }

            <div className="flex justify-between mt-16 mb-5">
                <h1 className='text-lg font-semibold'>Event Information</h1>
                {edit &&
                    <div className="p-1 px-4 border-2 border-gray-700 cursor-pointer h-fit"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit()
                        }}>
                        {!readOnly ? 'submit' : 'Edit'}
                    </div>
                }
            </div>
            {/* Title and Short Description */}
            <div className="flex flex-wrap items-center justify-between w-full">
                {/* Title */}
                <div className='flex flex-col w-[40%]'>
                    <div className="flex items-center justify-between">
                        <h1 className='text-lg'>Event Title</h1>
                        <h1 className='text-base'>(Limit 20 words)</h1>
                    </div>
                    <InputField value={eventData.title} onChange={(value) => updateState("title", value)} type={'text'} readOnly={readOnly} />
                </div>

            </div>

            {/* Slug,meta title and meta description */}

            <div className="flex flex-wrap items-center justify-between w-full">
                {/* Slug */}
                <div className='flex flex-col w-[40%]'>
                    <>
                        <div className='flex items-center justify-between w-full'>
                            <h1 className='text-[20px]'>Slug</h1>
                            {slugSuggestionBtnVisibility &&
                                <button onClick={generateSlug} className='p-2 px-4 text-white bg-brandRed rounded-2xl'>Generate Slug</button>
                            }
                        </div>
                        <InputField value={eventData.slug} onChange={(value) => updateState("slug", value)} type={'text'} readOnly={readOnly} />
                    </>
                    <>
                        <h1 className='text-[20px]'>Meta Title</h1>
                        <InputField value={eventData.meta_title} onChange={(value) => updateState("meta_title", value)} type={'text'} readOnly={readOnly} />
                    </>
                </div>
                {/* Description */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Meta Description</h1>
                    <textarea
                        readOnly={readOnly}
                        onChange={(e) => updateState("meta_description", e.target.value)}
                        className="h-[176px] w-full my-8 p-2 flex flex-col justify-center rounded-[2px] bg-white text-black border-2 border-gray-300" >
                        {eventData.meta_description}
                    </textarea>
                </div>

            </div>

            {/* Description and Image */}
            <div className="flex flex-wrap items-center justify-between w-full">
                {advanceCropping &&
                    <div className="h-[30vh] w-[50%] flex items-center justify-center">
                        <AdvancedImageCropping image={imgUrl} setPopup={() => setAdvanceCropping(!advanceCropping)} onCropComplete={handleCropComplete} ratio={11 / 6} />
                    </div>
                }
                {/* Description */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Event Description</h1>
                    <TextEditor setData={setEventDescription} data={eventDescription} />
                </div>
                {/* Image */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Image</h1>
                    <div onClick={handleButtonClick} className="h-[280px] w-full my-8 flex flex-col justify-center items-center rounded-[2px] bg-white text-black border-2 border-gray-300 cursor-pointer">
                        {imgUrl ? (
                            <img src={imgUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '280px' }} />
                        ) : (
                            <>
                                <p className='text-[9px] text-gray-500'>supports: PNG, JPG, JPEG</p>
                                <img src={img} alt="" />
                                <p className='text-[9px] text-gray-500'>Click here or drag file to this area to upload</p>
                            </>
                        )}
                        <input type="file" onChange={handleImageChange} style={{ display: 'none' }} ref={fileInputRef} />
                    </div>
                </div>
            </div>

            {/* Event Timing */}
            <div className='my-5'>
                <h1 className="text-xl font-bold text-black">Event Timing</h1>
                <p className="text-sm text-gray-600">Add time  that highlight what makes it unique <br />Event Time</p>
            </div>
            {/* Date and Time*/}
            <div className="flex flex-wrap items-center justify-between w-full">
                {/* Start Date */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Start Date</h1>
                    {/* value={formatDateToDisplayDateField(eventData.start_date)} */}
                    {edit ?
                        <InputField value={formatDateToDisplayDateField(eventData.start_date)} onChange={(value) => updateState("start_date", value)} type={'date'} readOnly={readOnly} />
                        :
                        <InputField onChange={(value) => updateState("start_date", value)} type={'date'} readOnly={readOnly} />
                    }
                </div>
                {/* Start Time */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Start Time</h1>
                    <InputField value={eventData?.start_time && convert12HourTo24Hour(eventData?.start_time)} onChange={(value) => updateState("start_time", value)} type={'time'} readOnly={readOnly} />
                </div>
            </div>
            {/* Date adn Time*/}
            <div className="flex flex-wrap items-center justify-between w-full">
                {/* End Date */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>End Date</h1>
                    {edit ?
                        <InputField value={formatDateToDisplayDateField(eventData?.end_date)} onChange={(value) => updateState("end_date", value)} type={'date'} readOnly={readOnly} />
                        :
                        <InputField onChange={(value) => updateState("end_date", value)} type={'date'} readOnly={readOnly} />
                    }
                </div>
                {/* End Time */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>End Time</h1>
                    <InputField value={eventData?.end_time && convert12HourTo24Hour(eventData?.end_time)} onChange={(value) => updateState("end_time", value)} type={'time'} readOnly={readOnly} />
                </div>
            </div>

            {/* Pricing And Coin Data */}
            <div className='my-5'>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-xl font-bold text-black">Is Free</h1>
                        <p className="text-sm text-gray-600">Add time  that highlight what makes it unique <br />Event Time</p>
                    </div>
                    <ToggleBtn checked={eventData.isFree} onChange={() => updateState("isFree", !eventData.isFree)} readOnly={readOnly} />
                </div>
            </div>
            {eventData.isFree === false &&
                <>

                    {/* Price*/}
                    <div className="flex flex-wrap items-center justify-between w-full">
                        {/* Event Amount */}
                        <div className='flex flex-col w-[40%]'>
                            <h1 className='text-[20px]'>Event Amount</h1>
                            <InputField value={eventData.price} onChange={(value) => updateState("price", value)} type={'number'} readOnly={readOnly} />
                        </div>
                        {/* price for couple */}
                        <div className='flex flex-col w-[40%]'>
                            <h1 className='text-[20px]'>For Couple Or Person</h1>
                            <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                                <DropDown
                                    readOnly={readOnly}
                                    selectedValue={eventData.price_for_couple ? 'couple' : "person"}
                                    placeholderValue={""}
                                    options={price_for_couple}
                                    handleOnChange={(value) => {
                                        if (value === 'couple') {
                                            updateState("price_for_couple", true)
                                        } else {
                                            updateState("price_for_couple", false)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Coin Redeemable */}
                    <div className='flex flex-col w-[40%]'>
                        <h1 className='text-[20px]'>Maximum Coin Redeemable</h1>
                        <InputField value={eventData.max_coin_redeemable} onChange={(value) => updateState("max_coin_redeemable", value)} type={'number'} readOnly={readOnly} />
                    </div>
                </>
            }
            {/* Slots And Seats */}
            <div className='my-5'>
                <h1 className="text-xl font-bold text-black">Slots And Seats</h1>
                <p className="text-sm text-gray-600">Add time  that highlight what makes it unique <br />Event Time</p>
            </div>
            <div className="flex flex-wrap items-center justify-between w-full">
                {/* Total Slot */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Total Available Slots</h1>
                    <InputField value={eventData.total_slots} onChange={(value) => updateState("total_slots", value)} type={'number'} readOnly={readOnly} />
                </div>
                {/* Different Slot for men and women */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Slots  Dividing for men and women </h1>
                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            readOnly={readOnly}
                            selectedValue={eventData.gender_based_slots ? 'yes' : "no"}
                            placeholderValue={""}
                            options={[{ label: "Yes", value: 'yes' }, { label: 'No', value: 'no' }]}
                            handleOnChange={(value) => {
                                if (value === 'yes') {
                                    updateState("gender_based_slots", true)
                                } else {
                                    updateState("gender_based_slots", false)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* Slot for men and women */}
            {eventData.gender_based_slots &&
                <div className="flex flex-wrap items-center justify-between w-full">
                    {/* Men */}
                    <div className='flex flex-col w-[40%]'>
                        <h1 className='text-[20px]'>Men</h1>
                        <InputField value={eventData.slots_for_men} onChange={(value) => updateState("slots_for_men", value)} type={'number'} readOnly={readOnly} />
                    </div>
                    {/* women */}
                    <div className='flex flex-col w-[40%]'>
                        <h1 className='text-[20px]'>Women</h1>
                        <InputField value={eventData.slots_for_female} onChange={(value) => updateState("slots_for_female", value)} type={'number'} readOnly={readOnly} />
                    </div>
                </div>
            }

            {/* Slots And Seats for PreBooking*/}
            <div className='my-5'>
                <div className="flex items-center justify-between">
                    <h1 className="text-xl font-bold text-black">Prebooking</h1>
                    <ToggleBtn checked={eventData.prebooking_enabled} onChange={() => updateState("prebooking_enabled", !eventData.prebooking_enabled)} readOnly={readOnly} />
                </div>
                <p className="text-sm text-gray-600">Add time  that highlight what makes it unique <br />Event Time</p>
            </div>

            {eventData.prebooking_enabled &&
                <>
                    {/* prebooking start */}
                    <div className="flex flex-wrap items-center justify-between w-full">
                        <div className='flex flex-col w-[40%]'>
                            <h1 className='text-[20px]'>Starts Date</h1>
                            {edit ?
                                <InputField value={formatDateToDisplayDateField(eventData.prebooking_from)} onChange={(value) => updateState("prebooking_from", value)} type={'date'} readOnly={readOnly} />
                                :
                                <InputField onChange={(value) => updateState("prebooking_from", value)} type={'date'} readOnly={readOnly} />
                            }
                        </div>
                        <div className='flex flex-col w-[40%]'>
                            <h1 className='text-[20px]'>End Date</h1>
                            {edit ?
                                <InputField value={formatDateToDisplayDateField(eventData.prebooking_to)} onChange={(value) => updateState("prebooking_to", value)} type={'date'} readOnly={readOnly} />
                                :
                                <InputField onChange={(value) => updateState("prebooking_to", value)} type={'date'} readOnly={readOnly} />
                            }
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-between w-full">
                        <div className='flex flex-col w-[40%]'>
                            <h1 className='text-[20px]'>Prebook Slots</h1>
                            {edit ?
                                <InputField value={eventData.prebooking_slots} onChange={(value) => updateState("prebooking_slots", value)} type={'text'} readOnly={readOnly} />
                                :
                                <InputField onChange={(value) => updateState("prebooking_slots", value)} type={'text'} readOnly={readOnly} />
                            }
                        </div>
                    </div>

                </>
            }

            {/* View Personalization */}
            <div className='my-5'>
                <h1 className="text-xl font-bold text-black">View Personalization</h1>
                <p className="text-sm text-gray-600">Add time  that highlight what makes it unique <br />Event Time</p>
            </div>
            <div className="flex flex-wrap items-center justify-between w-full">
                {/* Commands */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Enabling Comments On Event Page</h1>
                    <div className="h-[56px] w-full mb-8 mt-4 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            readOnly={readOnly}
                            selectedValue={eventData.enable_comments ? 'yes' : "no"}
                            placeholderValue={""}
                            options={[{ label: "Yes", value: 'yes' }, { label: 'No', value: 'no' }]}
                            handleOnChange={(value) => {
                                if (value === 'yes') {
                                    updateState("enable_comments", true)
                                } else {
                                    updateState("enable_comments", false)
                                }
                            }}
                        />
                    </div>
                </div>
                {/* Display Attendees */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Display Attendees List</h1>
                    <div className="h-[56px] w-full mb-8 mt-4 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            readOnly={readOnly}
                            selectedValue={eventData.display_attendees ? 'yes' : "no"}
                            placeholderValue={""}
                            options={[{ label: "Yes", value: 'yes' }, { label: 'No', value: 'no' }]}
                            handleOnChange={(value) => {
                                if (value === 'yes') {
                                    updateState("display_attendees", true)
                                } else {
                                    updateState("display_attendees", false)
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap items-center justify-between w-full">
                {/* Featured */}
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Make the event featured</h1>
                    <div className="h-[56px] w-full mb-8 mt-4 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            readOnly={readOnly}
                            selectedValue={eventData.featured ? 'yes' : "no"}
                            placeholderValue={""}
                            options={[{ label: "Yes", value: 'yes' }, { label: 'No', value: 'no' }]}
                            handleOnChange={(value) => {
                                if (value === 'yes') {
                                    updateState("featured", true)
                                } else {
                                    updateState("featured", false)
                                }
                            }}
                        />
                    </div>
                </div>

                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Location URL (Google Map)</h1>
                    <div className="h-[56px] w-full mb-8 mt-4 flex flex-col justify-center rounded-3xl">
                        <InputField value={eventData?.google_map_url} onChange={(value) => updateState("google_map_url", value)} type={'text'} readOnly={readOnly} />
                    </div>
                </div>
            </div>

            {edit &&
                <div className='flex flex-col w-[40%]'>
                    <h1 className='text-[20px]'>Event Status</h1>
                    <div className="h-[56px] w-full mb-8 mt-4 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            readOnly={readOnly}
                            selectedValue={eventData.publish_status}
                            placeholderValue={""}
                            options={[{ label: "Draft", value: 'draft' }, { label: 'Published', value: 'published' }, { label: 'Cancelled', value: 'cancelled' }, { label: 'Completed', value: 'completed' }]}
                            handleOnChange={(value) => setUpdatePublishStatus(({ popUp: true, publish_status: value }))}
                        />
                    </div>
                </div>
            }

            {edit === false &&
                <div className="flex items-center justify-center gap-5 mx-auto mt-10 mb-20 font-bold text-black bg-white rounded-lg shadow-lg w-fit">
                    <button className='p-2 px-4 border-gray-300 border-e-2 hover:text-gray-700 hover:font-medium hover:border-e-0 hover:border-b-4 hover:border-brandRed' onClick={handlePrevPage}>Back</button>
                    <button className='p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-b-4 hover:border-brandRed' onClick={handleNextPage}>Next</button>
                </div>
            }
        </div>
    )
}

export default memo(Index)