import { getAllPlansCategory } from 'apis/plans';
import React, { useEffect, useState } from 'react';
import { genderOptions } from 'shared/constants';
import { addLabelAndValueInData } from 'utils/dataConversion';
import CouponContentRender from '../CouponContentRender';

function Index({ data, handleSubmit, handleCancel }) {
    const CouponModeOptions = [
        { label: 'Plan Upgrade', value: 'plan_upgrade' },
        { label: 'Event Booking', value: 'event_booking' },
        { label: 'Coin Recharge', value: 'coin_recharge' },
    ]

    const [planCategory, setPlanCategory] = useState()
    const [couponData, setCouponData] = useState({
        code: data?.code,
        discount_percentage: data?.discount_percentage,
        max_discount_amount: data?.max_discount_amount,
        min_purchase_amount: data?.min_purchase_amount,
        valid_from: data?.valid_from,
        valid_to: data?.valid_to,
        selected_plan: data?.selected_plan,
        plan: data?.plan?.id,
        selected_gender: data?.selected_gender,
        gender: data?.gender,
        module: data?.module,
        status: data?.status,
        apply_type: data?.apply_type,
        apply_limit: data?.apply_limit,
        suggestable: data?.suggestable,
    })



    const content = [
        { label: 'Coupon Code', value: 'code', inputType: 'singleInput', type: 'text' },
        { label: 'Discount %', value: 'discount_percentage', inputType: 'singleInput', type: 'number' },

        { label: 'Min Purchase Amount', value: 'min_purchase_amount', inputType: 'singleInput', type: 'number' },
        { label: 'Max Discount Amount', value: 'max_discount_amount', inputType: 'singleInput', type: 'number' },
        { label: 'Validity', value1: 'valid_from', value2: 'valid_to', placeHolder1: 'From', placeHolder2: 'To', inputType: 'multipleInput', type: 'date' },

        { label: 'Gender', valueToggleBtn: 'selected_gender', valueDropDown: 'gender', options: genderOptions, inputType: 'ratioBtnAndDropdown' },
        { label: 'Plan Section', valueToggleBtn: 'selected_plan', valueDropDown: 'plan', options: planCategory, inputType: 'ratioBtnAndDropdown' },

        { label: 'Apply Type', value: 'apply_type', options: [{ label: 'Unlimited', value: 'unlimited' }, { label: 'Limited', value: 'limited' }], inputType: 'dropdown' },
        { label: 'Limit', value: 'apply_limit', inputType: 'singleInput', type: 'number' },
        { label: 'Suggestable', value: 'suggestable', options: [{ label: 'Yes', value: true }, { label: 'No', value: false }], inputType: 'dropdown' },

        { label: 'Coupon Mode', value: 'module', options: CouponModeOptions, inputType: 'dropdown' },
        { label: 'Status', value: 'status', options: [{ label: 'Active', value: true }, { label: 'Inactive', value: false }], inputType: 'dropdown' },


    ]

    const updateState = (key, value) => {
        setCouponData(prev => ({ ...prev, [key]: value }))
    }

    const fetchAllPlansCategory = async () => {
        const res = await getAllPlansCategory()
        const categories = addLabelAndValueInData(res?.data, 'name', 'id')
        setPlanCategory(categories)
    }

    useEffect(() => {
        fetchAllPlansCategory()
    }, [])

    return (
        <div className='w-[96%] mx-auto'>
            <div className=" w-full p-6 bg-white shadow-md rounded-md">
                <h2 className="text-xl font-bold mb-4">COUPON -Info</h2>
                <p className="mb-2">Add time that highlight what makes it unique</p>

                <div className="flex flex-wrap flex-col lg:flex-row justify-center items-start my-2 ">
                    {content && content.length > 0 && (
                        <>
                            <div className="w-full lg:w-1/2 px-2">
                                <CouponContentRender content={content?.slice(0, Math.ceil(content.length / 2))} updateState={updateState} couponData={couponData} />
                            </div>

                            <div className="w-full lg:w-1/2 px-2">
                                <CouponContentRender content={content?.slice(Math.ceil(content.length / 2))} updateState={updateState} couponData={couponData} />
                            </div>
                        </>
                    )}
                </div>



                <div className="flex justify-center mt-10">
                    <button type="button" className="px-4 py-2 bg-gray-200 text-gray-700 w-[120px] h-[50px]" onClick={handleCancel}>Cancel</button>
                    <button type="submit" className="px-4 py-2 bg-brandRed text-white w-[120px] h-[50px]" onClick={() => handleSubmit(couponData)}>Submit</button>
                </div>
            </div >
        </div>
    );
};

export default Index;