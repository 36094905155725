import React, { useState } from 'react';

function Index({ imageUrl, mainText, subText1, edit = false, handleRemove, viewUser }) {
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const hideDropdown = () => {
        setShowDropdown(false);
    };


    return (
        <div className="relative w-full p-4 overflow-hidden text-center bg-white border shadow-lg rounded-3xl">
            <div className="flex gap-2 mt-4">
                <img onClick={viewUser} className="object-cover w-full h-40 cursor-pointer rounded-3xl" src={imageUrl} alt="Card" />
            </div>

            <div className="mt-4 text-xl font-medium">{mainText}</div>
            <p className="my-2 text-gray-600">{subText1}</p>

            {/* Dropdown toggle button */}
            {edit &&
                <button onClick={toggleDropdown} className="absolute top-3 right-3">
                    <span className="text-3xl leading-none">⋮</span>
                </button>
            }

            {showDropdown && (
                <div className="absolute w-32 p-2 bg-white border rounded-md shadow-lg top-12 right-3">
                    <ul className="text-left">
                        <li className="p-2 cursor-pointer hover:bg-brandRed hover:text-white" onClick={handleRemove}>Remove</li>
                        <li className="p-2 cursor-pointer hover:bg-brandRed hover:text-white" onClick={hideDropdown}>Cancel</li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default React.memo(Index);
