import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { InputField } from "components"

function Index({ data, isEdited }, ref) {

    const [userData, setUserData] = useState({})

    const content = [
        { label: "Education", value: 'education' },
        { label: "Collage", value: 'college' },
        { label: "Profession", value: 'profession' },
        { label: "CTC", value: 'ctc' },
    ]

    const updateState = (key, value) => {
        isEdited()
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        setUserData({
            education: data?.education,
            college: data?.college,
            profession: data?.profession,
            ctc: data?.ctc,
        })
    }, [data])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Education Details</h1>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-fit">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='text-lg font-semibold'>{item.label}</h1>
                                <div className="w-[70%]">
                                    <InputField value={userData[item.value]} onChange={(value) => updateState(item.value, value)} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
