import { getUserPrivacySettings, updateUserPrivacySettings } from 'apis/users'
import { Checkbox, ToggleBtn } from "components"
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'


function Index({ decodedId, edit = false }) {

    const [userData, setUserData] = useState({
        hide_my_name: null,
        turn_off_discoverability: null,
        who_can_schedule_meetings: null
    })


    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }))
    }


    const fetchUserPrivacySettings = async () => {
        const res = await getUserPrivacySettings(decodedId)
        setUserData(res?.data[0]?.privacy)
    }

    const handleUpdateFilter = async () => {
        const res = await updateUserPrivacySettings(decodedId, userData)
        if (res?.success) {
            toast.success('updated successfully')
        }
    }


    useEffect(() => {
        fetchUserPrivacySettings()
    }, [])


    return (
        <div className='w-[50%] bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Privacy Settings</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="h-[1px] bg-gray-500 w-full my-5"></div>
                {/* Content Section */}
                <div className="">
                    {/* Name Section */}
                    <h1 className='font-semibold text-lg my-5'>Personal</h1>
                    <div className="flex justify-between mb-5">
                        <h1 className='text-base'>Hide My Name</h1>
                        {edit ?
                            <ToggleBtn checked={userData?.hide_my_name} onChange={() => updateState('hide_my_name', !userData?.hide_my_name)} />
                            : <h1 className='text-base'>{userData?.hide_my_name ? 'ON' : 'OFF'}</h1>
                        }
                    </div>
                    <h1 className='text-sm text-gray-500'>This will hide your name from others,Only people will able <br /> to see the first letter of your profile name</h1>

                    {/* Discover lability */}
                    <h1 className='font-semibold text-lg my-5'>Discover lability</h1>
                    <div className="flex justify-between mb-10">
                        <div>
                            <h1 className='text-base'>Profile Visibility</h1>
                            <div className="text-sm text-gray-500">Who can view my profile</div>
                        </div>
                        {edit ?
                            <ToggleBtn checked={userData?.turn_off_discoverability} onChange={() => updateState('turn_off_discoverability', !userData?.turn_off_discoverability)} />
                            : <h1 className='text-base'>{userData?.turn_off_discoverability ? 'ON' : 'OFF'}</h1>
                        }
                    </div>

                    {/* Meeting Scheduling */}
                    <div className="flex justify-between mb-5">
                        <h1 className='text-base'>Meeting Scheduling</h1>
                        <div className="">
                            <h1 className='text-base'>{userData?.who_can_schedule_meetings}</h1>
                            {edit &&
                                <div className="mt-2 flex flex-col">
                                    <Checkbox label={"Friends"} isChecked={userData?.who_can_schedule_meetings === 'friends_only' ? true : false} handleOnChange={() => updateState('who_can_schedule_meetings', 'friends_only')} />
                                    <Checkbox label={"No Body"} isChecked={userData?.who_can_schedule_meetings === 'nobody' ? true : false} handleOnChange={() => updateState('who_can_schedule_meetings', 'nobody')} />
                                </div>
                            }
                        </div>
                    </div>
                    <h1 className='text-sm text-gray-500 pb-10'>Specify Who can schedule meeting with you <br /> (Nobody,Friends Only)</h1>
                </div>
            </div>
            {edit &&
                <div className="flex w-full items-center justify-center">
                    <button onClick={handleUpdateFilter} className='bg-brandRed p-2 px-4 cursor-pointer text-white'>Save</button>
                </div>
            }
        </div>
    )
}

export default React.memo(Index)