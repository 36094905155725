import { fetchUserDetails } from 'apis/users';
import logo from 'assets/images/whitelogo.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React from 'react';
import ReactDOM from 'react-dom';
import { dateToAge } from 'shared/dates';
import { capitalize } from 'shared/textConverter';

const ProfileCard = ({ userData }) => {

  return (
    <div className="w-full font-sans">
      <div className="bg-[#f83758] p-4 py-6 flex items-center justify-between text-white">
        <div>
          <h2 className="text-xl">Membership ID - {userData?.wallet?.wallet_id}</h2>
          <h2 className="text-4xl">{userData?.name}</h2>
          <p className="text-2xl">{userData?.personal?.profession}</p>
        </div>
        {/* Use Base64 image here */}
              <img src={logo || 'default-image.png'} alt="Profile" className='w-auto h-auto'/>
              
      </div>

      <div className="flex justify-between p-5">
        {/* Left Section */}
        <div className="w-[48%]">
          {userData.leftSideContent?.map((item, index) => (
            <div className="w-full mt-14" key={index}>
              <h3 className="mb-3 text-4xl font-semibold">{item?.heading}</h3>
              {item?.values?.map((values, index) => (
                <div key={index} className="flex justify-between my-6 text-3xl">
                  <div>{values?.label}</div>
                  <div>{capitalize(values?.value)}</div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Right Section */}
              <div className="w-[48%]">
          {userData.rightSection?.map((item, index) => (
            <div className="w-full mt-14" key={index}>
              <h3 className="mb-3 text-4xl font-semibold">{item?.heading}</h3>
              {item?.values?.map((values, index) => (
                <div key={index} className="flex justify-between my-6 text-3xl">
                  <div>{values?.label}</div>
                  <div>{capitalize(values?.value)}</div>
                </div>
              ))}
            </div>
          ))}     
        </div>
      </div>
    </div>
  );
};


// Function to generate and download the PDF
export const generateProfilePDF = async (id) => {
  const res = await fetchUserDetails(id);
  const userData = res?.data[0];

  // Include leftSideContent and rightSection in userData
  userData.leftSideContent = [
    {
      heading: 'Basic Information',
      values: [
        { label: 'Name', value: userData?.name || 'Nil' },
        { label: 'Gender', value: userData?.personal?.sex || 'Nil' },
        { label: 'Age', value: dateToAge(userData?.personal?.dob) || 'Nil' },
        { label: 'Marital status', value: userData?.personal?.marital_status || 'Nil' },
        { label: 'Relationship status', value: userData?.personal?.relationship_status || 'Nil' },
        { label: 'Looking for', value: userData?.personal?.looking_for || 'Nil' },
        { label: 'Religion', value: userData?.religion || 'Nil' },
        { label: 'Caste', value: userData?.caste || 'Nil' },
      ],
    },
    {
      heading: 'Physical Details',
      values: [
        { label: 'Height', value: `${userData?.personal?.height}cm` || 'Nil' },
        { label: 'Weight', value: `${userData?.personal?.weight}kg` || 'Nil' },
        { label: 'Physical Status', value: userData?.personal?.physical_status || 'Nil' },
      ],
    },
    {
      heading: 'Education Details',
      values: [
        { label: 'Education', value: userData?.personal?.education || 'Nil' },
        { label: 'College', value: userData?.personal?.college || 'Nil' },
        { label: 'Profession', value: userData?.personal?.profession || 'Nil' },
      ],
    },
  ];

  userData.rightSection = [
    {
      heading: 'Diet Details',
      values: [
        { label: 'Drinking', value: userData?.misc?.drinking || 'Nil' },
        { label: 'Smoking', value: userData?.misc?.smoking || 'Nil' },
        { label: 'Eating', value: userData?.misc?.eating || 'Nil' },
      ],
    },
    {
      heading: 'Family Details',
      values: [
        { label: 'Parent Name', value: userData?.family?.parent_name || 'Nil' },
        { label: 'Relation Ship', value: userData?.family?.relationship || 'Nil' },
      ],
    },
    {
      heading: 'Languages Details',
      values: [
        { label: 'Mother Tongue', value: userData?.languages_known?.mother_tongue || 'Nil' },
        {
          label: 'Language Known',
          value: userData?.languages_known?.others?.length
            ? userData.languages_known.others.join(', ')
            : 'Nil',
        },
      ],
    },
    {
      heading: 'Interest Details',
      values: [
        {
          label: 'Interests',
          value: userData?.interests?.length
            ? userData.interests.join(', ')
            : 'Nil',
        },
      ],
    },
  ];


  // Create an off-screen React element in the DOM
  const element = document.createElement('div');
  document.body.appendChild(element);

  // Render the ProfileCard component inside the created element
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<ProfileCard userData={userData} />, element);

  // Generate PDF using html2canvas and jsPDF
  const canvas = await html2canvas(element, {
    scale: 1,        // Improve image resolution
    useCORS: true,   // Ensure cross-origin images are loaded properly
    allowTaint: false, // Prevent tainted canvases
  });

  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'a4',
  });

  const imgWidth = 595.28; // A4 width in points   
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  pdf.save(`${userData?.name}_profile.pdf`);

    // Clean up: remove the hidden element from the DOM
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.unmountComponentAtNode(element);
  element.remove();
};
