import { getUserEventHistory, getUserEventList } from 'apis/users'
import { EventsCard } from "components"
import React, { useEffect, useState } from 'react'
import { formatDate } from 'utils/dataConversion'

function Index({ decodedId, edit = false }) {

    const [currentTab, setCurrentTab] = useState('Booked')
    const header = ['Booked', 'upcoming', 'past', 'ongoing']

    const [pagination, setPagination] = useState({
        current_page: 1,
        next_page: null
    })
    const [eventList, setEventList] = useState()


    const fetchEvents = async (page) => {
        const res = currentTab === 'Booked' ? await getUserEventList(decodedId, page)
            : await getUserEventHistory(decodedId, page, currentTab)
        if (res) {
            setEventList(prev => ([...prev, ...res?.data]))
            setPagination(res?.pagination)
        }
    }

    useEffect(() => {
        setEventList([])
        fetchEvents(pagination.current_page)
    }, [currentTab])

    return (
        <div className='bg-white h-full text-black'>
            <div className="flex m-5 justify-between items-center">
                <div className="">
                    <h1 className='font-semibold text-xl pt-5'>Events</h1>
                    <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                </div>
                <div className="flex gap-10 me-5">
                    {header.map((item, index) => (
                        <div key={index} className={`cursor-pointer text-lg ${currentTab === item ? 'text-brandRed border-b-2 border-b-brandRed font-bold' : 'text-gray-500'}`} onClick={() => setCurrentTab(item)}>{item}</div>
                    ))}
                </div>
            </div>
            <div className="flex flex-wrap pb-20">
                {eventList?.map((item, index) => (
                    <div key={index} className="min-w-[200px] max-w-[200px] min-h-[200px] max-h-[200px] m-5 my-28">
                        <EventsCard
                            imageUrl={item?.event?.image}
                            mainText={item?.event?.title}
                            startDate={formatDate(item?.event?.start_date)}
                            endDate={formatDate(item?.event?.end_date)}
                            attendees_count={item?.attendees_count}
                            comments_count={item?.comments_count}

                            edit={edit}
                        />
                    </div>
                ))}
            </div>
            {pagination.next_page &&
                <div className="w-full text-center my-5 cursor-pointer text-base text-blue-500" onClick={() => fetchEvents(pagination.next_page)}>see more</div>
            }
        </div>
    )
}

export default React.memo(Index)