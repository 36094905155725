import React, { memo } from 'react';
import { Slider } from 'antd';

function Index({ minMaxRange, selectedMinMaxValue, onRangeChange, readOnly = false }) {

    const sliderStyle = {
        trackStyle: {
            backgroundColor: 'red',
        },
        handleStyle: {
            borderColor: 'red',
            backgroundColor: 'red',
        }
    };
    return (
        <div>
            <Slider
                disabled={readOnly}
                range
                value={selectedMinMaxValue}
                min={minMaxRange[0]}
                max={minMaxRange[1]}
                onChange={onRangeChange}
                trackStyle={sliderStyle.trackStyle}
                handleStyle={sliderStyle.handleStyle}
            />
        </div>
    );
}

export default memo(Index)
