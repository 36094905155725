import { UpdateBlog } from 'apis/blog';
import img from "assets/createNewEvent/images/Picture.png";
import { Steps } from 'components';
import AdvancedImageCropping from 'components/NEW/image/imageCropping';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

function Index({ saveData, onSaveSuccess, onSaveFailed, id, blogData }) {
    const [imgUrl, setImgUrl] = useState(blogData?.featured_image?.image_url || null)
    const [image, setImage] = useState(null)
    const [alt, setAlt] = useState(blogData?.featured_image?.alt_text)
    const [advanceCropping, setAdvanceCropping] = useState(false)

    const fileInputRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(file);
                setImgUrl(reader.result);
                setAdvanceCropping(!advanceCropping)
            };
            reader.readAsDataURL(file);
        } else {
            setImage(null);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleCropComplete = async (croppedFile) => {
        setImage(croppedFile);
        setImgUrl(URL.createObjectURL(croppedFile));
        setAdvanceCropping(!advanceCropping)
    }


    const handleUpdateData = async () => {
        const apiData = {
            image,
            alt_text: alt,
            id,
            current_index: 3,
        }
        const toastId = toast.loading('Creating blog')
        const res = await UpdateBlog(apiData)
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleUpdateData()
    }, [saveData])

    return (
        <div className="w-full p-6 mx-auto text-black bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} lineActive={true} />
                <Steps number={2} isActive={true} lineActive={true} />
                <Steps number={3} isActive={true} />
                <Steps number={4} />
                <Steps number={5} />
                <Steps number={6} displayLine={false} />
            </div>

            {/* Image Alt */}
            <div className="mb-6">
                {advanceCropping &&
                    <div className="h-[30vh] w-[50%] flex items-center justify-center">
                        <AdvancedImageCropping image={imgUrl} setPopup={() => setAdvanceCropping(!advanceCropping)} onCropComplete={handleCropComplete} ratio={11 / 6} />
                    </div>
                }
                <div className='flex flex-col w-full'>
                    <label className="block text-base font-semibold">Featured image</label>
                    <div onClick={handleButtonClick} className="h-[280px] w-full my-8 flex flex-col justify-center items-center rounded-[2px] bg-white text-black border-2 border-gray-300 cursor-pointer">
                        {imgUrl ? (
                            <img src={imgUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '280px' }} />
                        ) : (
                            <>
                                <p className='text-[9px] text-gray-500'>supports: PNG, JPG, JPEG</p>
                                <img src={img} alt="" />
                                <p className='text-[9px] text-gray-500'>Click here or drag file to this area to upload</p>
                            </>
                        )}
                        <input type="file" onChange={handleImageChange} style={{ display: 'none' }} ref={fileInputRef} />
                    </div>
                </div>
            </div>


            {/* Image Alt */}
            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Image Alt Text</label>
                <input type="text" value={alt} onChange={(e) => setAlt(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Alt Value"
                />
            </div>
        </div>
    )
}

export default Index
