import React, { useEffect, useState } from 'react';

// Components
import { fetchBlogById, fetchLatestBlogs } from 'apis/blog';
import { BLOG } from 'common/routes';
import ArticleContent from 'components/NEW/blog/viewBlog/articleContent';
import TableOfContent from 'components/NEW/blog/viewBlog/tableOfContent';
import { useParams } from 'react-router-dom';
import { formatDateToDDMMYY } from 'shared/dates';
import { decodeFromBase64, encodeToBase64 } from 'utils/encodeAndDecode';



function Index() {

    const { id } = useParams();
    const [blogData, setBlogData] = useState()
    const [latestBlog, setLatestBlog] = useState([])
    const [toc, setToc] = useState([])


    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = Array.from(doc.querySelectorAll('h2')).map((heading, index) => ({
            id: `heading-${index}`,
            text: heading.textContent,
        }));

        return headings;
    };

    const getBlogById = async () => {
        const decodedId = decodeFromBase64(id)
        const res = await fetchBlogById(decodedId)
        if (res?.success) {
            setBlogData(res?.data)
            const toc = extractHeadings(res?.data?.blog_content);
            setToc(toc);
        }
    }

    const getLatestBlogs = async () => {
        const res = await fetchLatestBlogs(5)
        if (res?.success) setLatestBlog(res?.data)
    }

    useEffect(() => {
        if (id) getBlogById()
        getLatestBlogs()
    }, [id])

    const handleOnClickLatestBlogTitle = (id) => {
        const url = BLOG.VIEW_BLOG.replace(':id', encodeToBase64(id));
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (

        <div className="min-h-screen bg-white">
            <div className="flex pt-[5%] p-8 w-[90%] mx-auto">
                {/* Left Sidebar */}
                <div className="w-1/4">
                    <TableOfContent toc={toc} />
                </div>

                {/* Main Content */}
                <div className="w-2/4">
                    <ArticleContent blogData={blogData} />
                </div>

                {/* Right Sidebar */}
                <div className="w-1/4">
                    <div className="w-full p-4 mt-5 text-black">
                        <h3 className="mb-4 text-xl font-bold">Blog Latest</h3>
                        <div className="space-y-4">
                            {latestBlog?.map((blog, idx) => (
                                <div key={idx} className="flex">
                                    <div className='mt-5'>
                                        <h4 className="text-base cursor-pointer" onClick={() => handleOnClickLatestBlogTitle(blog?.id)}>{blog?.title}</h4>
                                        <p className="mt-2 text-xs text-subText">{formatDateToDDMMYY(blog?.createdAt)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-10">
                            <img src={blogData?.advertisement_right_side?.image_url} alt={blogData?.advertisement_right_side?.alt_text} className='w-fit h-fit' />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index;
