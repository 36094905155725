import { ManagementSecMainHeader, TableWithoutPagination } from 'components'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { toast } from "react-toastify"
import { createTags, getAllActiveEventTags, updateTags } from '../../../apis/events'

const AddAndEditTag = lazy(() => import("components").then(module => ({ default: module.AddAndEditTag })))


function Index() {

    const [showTagModule, setShowTagModule] = useState(false)
    const [tagData, setTagData] = useState()
    const [selectedTag, setSelectedTag] = useState()
    const [isEdit, setIsEdit] = useState(false)

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Tag Name', accessor: 'name' },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Inactive
                        </span>
                    }
                </>

            )
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )

        },
    ];


    const handleEdit = async (row) => {
        setIsEdit(true)
        setSelectedTag(row)
        setShowTagModule(!showTagModule)
    };

    const fetchEventTags = async () => {
        const res = await getAllActiveEventTags()
        setTagData(res?.data)
    }

    useEffect(() => {
        fetchEventTags()
    }, [])

    const handleSubmit = async (value) => {
        const operation = isEdit ? 'updating' : 'creating';
        const successMessage = isEdit ? 'Updated Tag successfully...' : 'Created Tag successfully...';
        const toastId = toast.loading(`${operation} Tag`);
        try {
            const res = isEdit ? await updateTags(selectedTag.id, value) : await createTags(value);
            toast.dismiss(toastId);
            if (res.success) {
                toast.success(successMessage);
                if (isEdit) {
                    setIsEdit(false);
                }
                setShowTagModule(false);
                fetchEventTags();
            }
        } catch (error) {
            toast.error(`Failed to ${operation.toLowerCase()} Tag.`);
            toast.dismiss(toastId);
        }
    };



    const handleClose = () => {
        setShowTagModule(!showTagModule)
        setIsEdit(false)
        setSelectedTag(null)
    }

    return (
        <>
            <div className="flex flex-col h-full">
                {showTagModule && (
                    // <div className="absolute inset-0 z-50 flex items-center justify-center h-screen bg-black bg-opacity-50">
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AddAndEditTag handleClose={handleClose} handleSubmit={handleSubmit} data={selectedTag} />
                        </Suspense>
                    </div>
                )}


                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-full w-full">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10 ">
                        <ManagementSecMainHeader
                            mainHeading={"Event"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Add Tag"}
                            handleBtn={() => setShowTagModule(!showTagModule)} />
                    </div>

                    {/* Table */}
                    <div className="w-full h-full p-8">
                        {tagData &&
                            <TableWithoutPagination columns={columns} data={tagData} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index