import { Switch } from 'antd';
import { DropDown, RangeSliderDoublePointer } from "components";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { interests } from '../../../../../../../shared/constants';
import { addLabelAndValueInData } from '../../../../../../../utils/dataConversion';
import languages from "../../../../../../../assets/json/languages.json";

function Index({ data }, ref) {
    const [userData, setUserData] = useState()

    const languagesOptions = useMemo(() => addLabelAndValueInData(languages, "name", "name"), [languages]);



    const content = [
        // { label: "Looking For", value: 'lookingFor', mode: "", isSlider: false, options: couplesLookingForOptionsCouples }, // data?.gender === 'single' ? singlesLookingForOptions :
        { label: "Age", value1: 'age_from', value2: 'age_to', isSlider: true, checkBox: 'age_expansion', minMaxRange: [18, 50] },
        { label: "Height", value1: 'height_from', value2: 'height_to', isSlider: true, checkBox: 'height_expansion', minMaxRange: [150, 200] },
        { label: "Distance Preferred", value1: 'distance_from', value2: 'distance_to', isSlider: true, checkBox: false, minMaxRange: [2, 200] },
        { label: "Interests", value: 'interests', mode: "multiple", isSlider: false, options: interests },
        { label: "Language Known", value: 'languages_known', mode: "multiple", isSlider: false, options: languagesOptions },
    ];

    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }));
    }


    useEffect(() => {
        if (data) setUserData(data)
    }, [data])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Basic Filter</h1>
                <div className="flex flex-col justify-between">
                    {content.map((item, index) => (
                        <div key={index} className="flex items-center justify-between">
                            <div className='flex flex-col'>
                                <h1 className='my-6 text-lg font-semibold'>{item?.label}</h1>
                                {item?.checkBox &&
                                    <h1 className='my-6 text-lg font-semibold'>Expand {item?.label}</h1>
                                }
                            </div>
                            <div className="w-[70%]">
                                {item?.isSlider ? (
                                    <div className='w-full h-32 my-2 border-2 border-gray-300 rounded cursor-pointer'>
                                        <div className='flex flex-col justify-center mt-4'>
                                            <div className='flex flex-col justify-center px-4'>
                                                <p className='text-black text-[15px] font-normal'>{`Selected ${item?.label} Range: ${userData?.[item?.value1]} to ${userData?.[item?.value2]}`}</p>
                                                <RangeSliderDoublePointer
                                                    minMaxRange={item?.minMaxRange}
                                                    selectedMinMaxValue={[userData?.[item?.value1], userData?.[item?.value2]]}
                                                    onRangeChange={(value) => {
                                                        updateState(item?.value1, value[0])
                                                        updateState(item?.value2, value[1])
                                                    }}
                                                />
                                            </div>
                                            {item?.checkBox &&
                                                <div className='flex justify-between px-4'>
                                                    <p className='text-[14px] text-gray-500'>
                                                        See people either side if I run out
                                                    </p>
                                                    <Switch checked={userData?.[item?.checkBox]} onChange={() => updateState(item?.checkBox, !userData?.[item?.checkBox])} className={true ? 'bg-gray-700' : 'bg-gray-400'} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <DropDown
                                        selectedValue={userData?.[item?.value]}
                                        mode={item?.mode}
                                        options={item?.options}
                                        handleOnChange={(value) => updateState(item?.value, value)}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default React.memo(forwardRef(Index));