export const initializeUserDetailState  = (data) => {
    return {
        verified:data?.verification?.status,
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        dob: data?.personal?.dob,
        sex: data?.personal?.sex,
        relationship_status: data?.personal?.relationship_status,
        no_of_children: data?.personal?.no_of_children || 'nill',
        marital_status: data?.personal?.marital_status,
        looking_for: data?.personal?.looking_for,
        interests: data?.interests,
        religion_id: data?.religion?.id,
        caste_id: data?.caste?.id,
        contact_mode: data?.contact_mode,
        height: data?.personal?.height,
        weight: data?.personal?.weight,
        physical_status: data?.personal?.physical_status,
        ctc: data?.personal?.ctc,
        drinking: data?.misc?.drinking,
        smoking: data?.misc?.smoking,
        eating: data?.misc?.eating,
        citizenship: data?.address?.citizenship,
        country: data?.address?.country,
        current_city: data?.address?.current_city,
        state: data?.address?.state,
        education: data?.personal?.education,
        college: data?.personal?.college,
        profession: data?.personal?.profession,
        parent_name: data?.family?.parent_name,
        relationship: data?.family?.relationship,
        contact_number: data?.family?.contact_number,
        mother_tongue: data?.languages_known?.mother_tongue,
        others: data?.languages_known?.others,
        images:data?.images,
    }
} 


export const initializeUserBasicAndAdvanceFilterState = (basic,advanced) =>{
     // Basic
    const basicDetails = {
     age_from:basic?.age_from,
     age_to:basic?.age_to,
     age_expansion:basic?.age_expansion,
     
     height_from:basic?.height_from,
     height_to:basic?.height_to,
     height_expansion:basic?.height_expansion,
     
     distance_from:basic?.distance_from,
     distance_to: basic?.distance_to,

     interests:basic?.interests,
     languages_known: basic?.languages_known,
    }
     
    //  Advanced
    const advancedDetails = {
     religion_id:advanced?.religion_id?.map(religion => religion?._id) || [],
     caste_id:advanced?.caste_id?.map(cast => cast?._id) ||[],
     drinking:advanced?.drinking ||[],
     city:advanced?.city,
     eating:advanced?.eating ||[],
     education:advanced?.education ||[],
     looking_for:advanced?.looking_for ||[],
     marital_status:advanced?.marital_status || [],
     smoking:advanced?.smoking || [],
     workout:advanced?.workout || [],
     show_everyone:advanced?.show_everyone,
     verified_profile:advanced?.verified_profile,
    }
    return {basicDetails,advancedDetails}
}

export const structuredApiData = (data) => {
    return {
        name:data?.name ,
        email:data?.email ,
        phone:data?.phone ,
        contact_mode:data?.contact_mode ,
        personal: {
            height:data?.height ,
            weight:data?.weight ,
            physical_status:data?.physical_status,
            sex:data?.sex ,
            relationship_status:data?.relationship_status,
            marital_status:data?.marital_status ,
            looking_for:data?.looking_for ,
            education:data?.education ,
            college:data?.college ,
            profession:data?.profession ,
            dob:data?.dob ,
            ctc:data?.ctc,
            no_of_children:data?.no_of_children || 'nill',
            religion_id:data?.religion_id ,
            caste_id:data?.caste_id 
        },
        misc: {
            drinking:data?.drinking,
            smoking:data?.smoking,
            eating:data?.eating,
            interests:data?.interests
        },
        address: {
            country:data?.country,
            state:data?.state,
            current_city:data?.current_city,
            citizenship:data?.citizenship,
        },
        family: {
            parent_name:data?.parent_name,
            relationship:data?.relationship,
            contact_number:data?.contact_number
        },
        languages_known: {
            mother_tongue: data?.mother_tongue,
            others: data?.others
        }
    }
}

export const structuredUserFilterApiData = (data) => {
    return {
        
            basic: {
                age_from:data?.basicDetails?.age_from,
                age_to:data?.basicDetails?.age_to,
                age_expansion:data?.basicDetails?.age_expansion,
                height_from:data?.basicDetails?.height_from,
                height_to:data?.basicDetails?.height_to,
                height_expansion:data?.basicDetails?.height_expansion,
                distance_from:data?.basicDetails?.distance_from,
                distance_to:data?.basicDetails?.distance_to,
                languages_known:data?.basicDetails?.languages_known,
                interests:data?.basicDetails?.interests,
                mother_tongue:data?.basicDetails?.mother_tongue,
            },
            advanced: {
                verified_profile:data?.advancedDetails?.verified_profile,
                looking_for:data?.advancedDetails?.looking_for || [],
                timeline:data?.advancedDetails?.timeline,
                workout:data?.advancedDetails?.workout,
                city:data?.advancedDetails?.city,
                physical_status:data?.advancedDetails?.physical_status, 
                eating:data?.advancedDetails?.eating || [],
                smoking:data?.advancedDetails?.smoking || [],
                drinking:data?.advancedDetails?.drinking || [],
                religion_id:data?.advancedDetails?.religion_id || [], 
                caste_id:data?.advancedDetails?.caste_id || [],
                education:data?.advancedDetails?.education || [],
                show_everyone:data?.advancedDetails?.show_everyone ,
                marital_status:data?.advancedDetails?.marital_status || [],
            }
        }
    
}