import { auth } from "..";

export async function addReligion (data) {
    const res = await auth({ method: "POST", url: `/admin/religions`, data });
    return res;
};

export async function fetchReligions () {
    const res = await auth({ method: 'GET', url: '/admin/religions'});
    return res;
};

export async function editReligion (data, id) {
    const res = await auth({ method: 'PUT', url: `/admin/religions/${id}`, data});
    return res;
}

export async function fectchCastes(){
    const res = await auth({ method: 'GET', url: '/admin/castes'});
    return res;
}

export async function addCaste(data) {
    const res = await auth({ method: 'POST', url: '/admin/castes', data});
    return res;
};

export async function editCaste (data, id) {
    const res = await auth({method: 'PUT', url: `/admin/castes/${id}`, data});
    return res;
}

export async function getCastesByReligion (id) {
    const res = await auth({method: 'GET', url: `/admin/castes/${id}`});
    return res;
}