import { ManagementSecMainHeader, Table } from 'components'
import moment from 'moment'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { toast } from "react-toastify"
import { EVENTS } from "../../../common/routes.js"

import EventCard from 'components/NEW/cards/publishedEvents'
import DropDown from 'components/NEW/ui/dropDown/index.jsx'
import { eventStatus } from 'shared/constants.js'
import { deleteEventById, duplicateEventById, enableEventConnection, getAllEvents, updateEventOnlineLink, updateEventStatus } from 'apis/events'
import { formatStringToCapitalist } from '../../../utils/dataConversion.js'
import { encodeToBase64 } from '../../../utils/encodeAndDecode.js'
const EventLink = lazy(() => import('components/NEW/popups/eventLink'))

function Index() {

    const [status, setStatus] = useState('published')
    const [duplicate, setDuplicate] = useState(false)
    const [duplicateEventId, setDuplicateEventId] = useState()
    const [eventData, setEventData] = useState([])
    const [eventLink, setEventLink] = useState({
        visibility: false,
        evetnId: null,
        link: null
    })
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Event Name', accessor: 'title', maxWidth: 100 },
        {
            Header: 'Event Category',
            accessor: (data) => data?.categories?.map(cat => cat?.name)?.join(', ')
        },
        { Header: 'Event Timing', accessor: 'start_time' },
        { Header: 'Total Slots', accessor: 'total_slots' },
        { Header: 'Event Location', accessor: 'location' },
        {
            Header: 'Date',
            accessor: (data) => moment(data?.start_date)?.format('YYYY-MM-DD')
        },
        {
            Header: 'Event Type',
            accessor: 'mode',
            Cell: ({ row }) => (
                <span className={`cursor-pointer ${row?.original?.mode == 'Online' ? 'text-green-500' : 'text-black'}`} onClick={() => row?.original?.mode === 'Online' && setEventLink({ visibility: true, link: row?.original?.online_event_link, evetnId: row?.original?.id })}>
                    {row?.original?.mode}
                </span>
            )
        },
        {
            Header: 'Event Status',
            accessor: 'publish_status',
            Cell: ({ row }) => (
                <div>
                    {status === 'local' ?
                        <h4>Status Change not possible</h4>
                        :
                        <DropDown
                            selectedValue={row?.original?.publish_status}
                            options={eventStatus}
                            handleOnChange={(value) => handleUpdateEventStatus(row?.original?.id, value)}
                        />
                    }
                </div>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    {status === 'local' ?
                        <button onClick={() => handleEdit(row?.original?.slug)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                        :
                        <>
                            <button onClick={() => handleEdit(row?.original?.id)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                            <button onClick={() => handleDuplicate(row?.original?.id)} className='p-2 mr-1 text-white bg-green-500 rounded-lg'>Duplicate</button>
                            <button onClick={() => handleView(row?.slug, false)} className='bg-[#6161f8] text-white mr-1 p-2 rounded-lg'>View</button>
                            {row.original.publish_status === 'Draft' && (
                                <button onClick={() => handleDelete(row?.original?.id)} className='p-2 mr-1 text-white bg-red-500 rounded-lg'>Delete</button>
                            )}
                        </>
                    }
                </div>
            )
        }
    ];

    const handleConnection = async (id) => {
        const res = await enableEventConnection(id)
        if (res.success) {
            toast.success("Updated Event Connection")
            fetchAllTheEvents(pageNation.currentPage)
        }
    };

    const handleViewParticipant = (id) => {
        const url = (`${EVENTS.VIEW_EVENT_PARTICIPANT}/${encodeToBase64(id)}`)
        window.open(url, '_blank', 'noopener,noreferrer')
    };

    const handleEdit = (id) => {
        const url = `${EVENTS.CREATE_AND_EDIT_EVENT_BASIC}/${encodeToBase64(status == 'local' ? 'local' : 'edit')}/${encodeToBase64(id)}`;
        window.open(url, '_blank', 'noopener,noreferrer')
    };


    const handleDuplicate = (id) => {
        setDuplicate(!duplicate)
        setDuplicateEventId(id)
    };

    const duplicateEventApi = async () => {
        const res = await duplicateEventById(duplicateEventId)
        if (res?.success) {
            toast.success("Event Duplicated Successfully...")
        }
        fetchAllTheEvents(pageNation.currentPage)
        setDuplicate(!duplicate)
    }


    const handleView = (slug, comment) => {
        const url = `${EVENTS.VIEW_EVENT}/${encodeToBase64(slug)}/${comment} `
        window.open(url, '_blank', 'noopener,noreferrer')
    };

    const handleDelete = async (id) => {
        const res = await deleteEventById(id)
        if (res?.success) {
            toast.success("Event Deleted Successfully...")
        }
        fetchAllTheEvents(pageNation.currentPage)
    };

    const handleUpdateEventOnlineLink = async (id, link) => {
        const data = {
            online_event_link: link
        }
        const res = await updateEventOnlineLink(id, data)
        if (res?.success) {
            setEventLink({ visibility: false, eventId: null, link: null })
            toast.success("Event Online Link Updated Successfully...")
        }
        fetchAllTheEvents(pageNation.currentPage)
    }

    const handleUpdateEventStatus = async (id, value) => {
        const res = await updateEventStatus(id, value)
        if (res?.success) {
            toast.success("Event Status Updated Successfully...")
        }
        fetchAllTheEvents(pageNation.currentPage)
    }

    const fetchAllTheEvents = async (page) => {
        const res = await getAllEvents(page, status)
        const event = res?.data?.events.map((event, index) => ({
            ...event,
            title: formatStringToCapitalist(event?.title),
            mode: formatStringToCapitalist(event?.mode),
            publish_status: formatStringToCapitalist(event?.publish_status),
            no: index + 1,
            // start_time: timeToHrAndMin(event?.start_time),
            categories: event?.categories?.map(cat => ({
                ...cat,
                name: formatStringToCapitalist(cat.name)
            }))
        }));
        setEventData(event)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        if (status === 'local') {
            setEventData()
            const storedEventData = JSON.parse(localStorage.getItem('eventData')) || [];
            const eventList = storedEventData.map(item => Object.values(item)[0]);
            setEventData(eventList);
        } else {
            fetchAllTheEvents(pageNation.currentPage)
        }
    }, [status])

    const handleBtn = () => {
        const url = `${EVENTS.CREATE_AND_EDIT_EVENT_BASIC}/${encodeToBase64('create')}`
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    return (
        <div className='flex flex-col h-screen'>
            {eventLink.visibility &&
                <Suspense>
                    <EventLink
                        onClose={() => setEventLink({ visibility: false, eventId: null, link: null })}
                        text={eventLink.link}
                        onConfirm={(link) => handleUpdateEventOnlineLink(eventLink.evetnId, link)}
                    />
                </Suspense>
            }

            {duplicate &&
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="bg-white p-4 rounded-lg shadow-lg h-[290px] w-[454px] flex flex-col justify-center items-center text-center">
                        <h2 className="text-lg font-bold">Duplicate Event Confirmation</h2>
                        <p className="mt-2 mb-4 w-[308px] text-gray-500">Add time to highlight what makes it unique Event Time</p>
                        <div className="flex gap-5">
                            <button className="px-4 py-2 text-black bg-gray-300 rounded" onClick={() => setDuplicate(!duplicate)}>Cancel</button>
                            <button className="px-4 py-2 mr-2 text-white bg-red-500 rounded" onClick={() => duplicateEventApi()}>Confirm</button>
                        </div>
                    </div>
                </div>
            }

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Event"}
                        subHeading={"Manage your events effectively"}
                        btnText={"Add Event"}
                        handleBtn={handleBtn} />
                </div>

                {/* Table */}
                <div className="p-8">

                    <div className="flex">
                        <button onClick={() => setStatus('published')} className={`p-2 px-6 border ${status === 'published' && 'bg-gray-300'}`}>Published</button>
                        <button onClick={() => setStatus('draft')} className={`p-2 px-6 border-2 border-b-0 ${status === 'draft' && 'bg-gray-300'}`}>Draft</button>
                        <button onClick={() => setStatus('cancelled')} className={`p-2 px-6 border-2 border-b-0 ${status === 'cancelled' && 'bg-gray-300'}`}>Cancelled</button>
                        <button onClick={() => setStatus('completed')} className={`p-2 px-6 border-2 border-b-0 ${status === 'completed' && 'bg-gray-300'}`}>Completed</button>
                        <button onClick={() => setStatus('local')} className={`p-2 px-6 border-2 border-b-0 ${status === 'local' && 'bg-gray-300'}`}>Local</button>
                    </div>

                    {status === 'published' || status === 'completed' ?
                        <div>
                            <div className="flex flex-wrap gap-4 mt-10">
                                {eventData?.map((item) => (
                                    <EventCard
                                        key={item?.id}
                                        eventImg={item?.image}
                                        totalBookings={item?.total_bookings}
                                        status={item?.publish_status}
                                        connection={item?.enable_connections}
                                        eventName={item?.title}
                                        eventCategory={item?.categories}
                                        eventTime={item?.start_time}
                                        eventDate={moment(item.start_date).format('YYYY-MM-DD')}
                                        totalSlots={item?.total_slots}
                                        eventType={item?.mode}
                                        totalPrebooked={item?.total_prebookings}
                                        location={item?.location}
                                        totalComments={item?.total_comments}
                                        totalParticipants={item?.total_bookings}

                                        handleDelete={() => handleDelete(item?.id)}
                                        handleDuplicate={() => handleDuplicate(item?.id)}
                                        handleEdit={() => handleEdit(item?.id)}
                                        handleViewEventParticipants={() => handleViewParticipant(item?.id)}
                                        handleViewEventComments={() => handleView(item?.slug, true)}
                                        handleViewEvent={() => handleView(item?.slug, false)}
                                        handleUpdateEventConnectionStatus={() => handleConnection(item?.id)}
                                        handleUpdateEventStatus={(value) => handleUpdateEventStatus(item?.id, value)}
                                        updateEventLink={() => setEventLink({ visibility: true, link: item?.online_event_link, evetnId: item?.id })}

                                    />
                                ))}

                            </div>

                            <div className="flex items-center justify-center gap-5 mx-auto mt-10 mb-20 font-bold text-black bg-white rounded-lg shadow-lg w-fit">
                                {pageNation.prevPage &&
                                    <button className='p-2 px-4 border-gray-300 border-e-2 hover:text-gray-700 hover:font-medium hover:border-e-0 hover:border-b-4 hover:border-brandRed' onClick={() => fetchAllTheEvents(pageNation.prevPage)}>Prev</button>
                                }
                                {pageNation.nextPage &&
                                    <button className='p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-b-4 hover:border-brandRed' onClick={() => fetchAllTheEvents(pageNation.nextPage)}>Next</button>
                                }
                            </div>

                        </div>
                        :
                        <>
                            {eventData.length > 0 &&
                                <Table columns={columns} data={eventData} pageNation={pageNation} handlePageNation={(value) => fetchAllTheEvents(value)} />
                            }
                        </>
                    }

                </div>
            </div>
        </div>
    )
}

export default Index