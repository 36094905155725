import React, { useState, useEffect } from 'react';
import { InputField, DropDown } from "components"

import { toast } from "react-toastify"
import { Country, State, City } from "country-state-city";
import { addLabelAndValueInData } from '../../../../utils/dataConversion';


function Index({ handleClose, handleSubmit, data }) {
    const [locationData, setLocationData] = useState({
        name: data?.name || null,
        city: data?.city || null,
        state: data?.state || null,
        country: data?.country || null,
        status: data?.status ?? true,
        visibility: data?.visibility ?? true
    });


    const updateState = (key, value) => {
        setLocationData(prev => ({ ...prev, [key]: value }))
    }


    const handleCreateBtn = () => {
        if (locationData.name && locationData.city && locationData.state && locationData.country) {
            handleSubmit(locationData)
        } else {
            toast.error("please fill all the Fields")
        }
    }

    // Country State and City Handling
    const [statesOptions, setStatesOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const country = Country.getAllCountries();
    const countryOptions = addLabelAndValueInData(country, "name", "name");

    // Update states when country changes
    useEffect(() => {
        if (locationData.country) {
            const selectedCountry = country.find(c => c.name === locationData.country);
            if (selectedCountry) {
                const states = State.getStatesOfCountry(selectedCountry.isoCode);
                const formattedStates = addLabelAndValueInData(states, "name", "name");
                setStatesOptions(formattedStates);
            } else {
                setStatesOptions([]);
            }
        } else {
            setStatesOptions([]);
        }
    }, [locationData.country]);


    // Update cities when state changes
    useEffect(() => {
        if (locationData.state && locationData.country) {
            const selectedCountry = country.find(c => c.name === locationData.country);
            if (selectedCountry) {
                const selectedState = State.getStatesOfCountry(selectedCountry.isoCode).find(s => s.name === locationData.state);
                if (selectedState) {
                    const cities = City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
                    const formattedCities = addLabelAndValueInData(cities, "name", "name");
                    setCityOptions(formattedCities);
                } else {
                    setCityOptions([]);
                }
            } else {
                setCityOptions([]);
            }
        } else {
            setCityOptions([]);
        }
    }, [locationData.state, locationData.country]);


    return (
        <div className="flex items-center justify-center w-full h-full">
            <div className="relative w-full max-w-md p-6 text-black bg-white rounded-lg shadow-lg">
                <button
                    onClick={handleClose}
                    className="absolute top-0 right-0 p-2 mt-2 mr-2 text-xl leading-none text-black"
                >
                    &#x2715;
                </button>
                <h2 className="mb-6 text-xl font-semibold text-start">Create Location</h2>
                <div className="space-y-4">
                    <h1 className='text-[16px]'>Name</h1>
                    <InputField value={locationData.name} onChange={(value) => updateState("name", value)} type={'text'} />

                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <h1 className='text-[16px] mt-4 '>Country</h1>
                        <DropDown
                            selectedValue={locationData.country}
                            placeholderValue={"Select Country"}
                            options={countryOptions}
                            handleOnChange={(value) => updateState("country", value)}
                        />
                    </div>
                    <h1 className='text-[16px] mt-6'>State</h1>
                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            selectedValue={locationData.state}
                            placeholderValue={"Select State"}
                            options={statesOptions}
                            handleOnChange={(value) => updateState("state", value)}
                        />
                    </div>
                    <h1 className='text-[16px] mt-4 -mb-3'>City</h1>
                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            selectedValue={locationData.city}
                            placeholderValue={"Select city"}
                            options={cityOptions}
                            handleOnChange={(value) => updateState("city", value)}
                        />
                    </div>
                    <h1 className='text-[16px] mt-4 -mb-3'>Status</h1>
                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            selectedValue={locationData.status ? 'yes' : "no"}
                            placeholderValue={""}
                            options={[{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]}
                            handleOnChange={() => updateState("status", !locationData.status)}
                        />
                    </div>
                    <h1 className='text-[16px] mt-6'>Visibility</h1>
                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            selectedValue={locationData.visibility}
                            placeholderValue={"Select visibility"}
                            options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                            handleOnChange={(value) => updateState("visibility", value)}
                        />
                    </div>


                    <button
                        onClick={handleCreateBtn}
                        type="button"
                        className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                    >
                        Create
                    </button>

                </div>
            </div>
        </div>

    );
}

export default React.memo(Index)
