export const verifiedMessage = {
    title:'Your Profile Has Been Successfully Verified',
    message:'Congratulations! You’ve unlocked full access to the CoupleSquad community. Connect, explore events, and make meaningful connections with ease.',
}

export const rejectedMessage = {
    title:' Profile Verification Unsuccessful',
    message:'Unfortunately, your profile did not meet our verification criteria. You can update your details and try again. If you need assistance, feel free to reach out to us at hello@couplesquad.com. We’re here to help!',
}

export const  waitingListMessage = {
    title:'Your Profile is on the Waitlist',
    message:'Thanks for joining CoupleSquad! Your profile is currently on the waitlist and will be reviewed soon. Stay tuned, we’ll notify you once the process is complete. For any questions, contact us at hello@couplesquad.com.',
}
