import { ManagementSecMainHeader, Table } from 'components';
import React, { lazy, Suspense, useEffect, useState } from 'react';

const AddAndEditReferrals = lazy(() => import("components").then(module => ({ default: module.AffiliateCoupons })));

import { createAffiliateCoupon, getAllAffiliateCoupons, updateAffiliateCoupon } from 'apis/affiliateCoupons';
import { AFFILIATE_COUPONS } from 'common/routes';
import { encodeToBase64 } from 'utils/encodeAndDecode';

const index = () => {


    const [isEdit, setIsEdit] = useState(false)
    const [couponData, setCouponData] = useState()
    const [selectedCoupon, setSelectedCoupon] = useState()
    const [addAndEditCoupon, setAddAndEditCoupon] = useState(false)
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })


    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'influencer_name' },
        { Header: 'Email', accessor: 'influencer_email' },
        { Header: 'Phone', accessor: 'influencer_phone' },
        { Header: 'Referral Code', accessor: 'referral_code' },
        { Header: 'Referral Coins', accessor: 'coins' },
        { Header: 'Validity', accessor: 'coins_validity' },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Inactive
                        </span>
                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className='flex gap-3'>
                    <button onClick={() => handleEdit(row)} className='px-3 py-1 mr-1 text-white bg-red-500 rounded-full'>Edit</button>
                    <button onClick={() => handleView(row)} className='px-3 py-1 mr-1 text-white bg-blue-700 rounded-full'>View</button>
                </div>
            )
        }
    ];

    const handleEdit = (row) => {
        setIsEdit(true)
        setSelectedCoupon(row?.original)
        setAddAndEditCoupon(true)
    };

    const handleView = (row) => {
        setSelectedCoupon(row?.original)
        const id = encodeToBase64(row?.original?.id);
        const url = AFFILIATE_COUPONS.TRACK_AFFILIATE_COUPON.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const fetchAllCoupons = async (page) => {
        const res = await getAllAffiliateCoupons(page)
        setCouponData(res?.data)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    };

    useEffect(() => {
        fetchAllCoupons(1)
    }, [])

    const handleBtn = () => {
        setAddAndEditCoupon(true)
    }

    const handleClose = () => {
        setAddAndEditCoupon(false)
        setIsEdit(false)
        setSelectedCoupon(null)
    }

    const handleSubmit = async (value) => {
        const res = isEdit ? await updateAffiliateCoupon(selectedCoupon.id, value) : await createAffiliateCoupon(value);
        if (res.success) {
            if (isEdit) {
                setIsEdit(false);
            }
            setAddAndEditCoupon(false);
            fetchAllCoupons(1);
        }

    };

    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] min-h-screen h-full w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Affiliate Coupons"}
                        subHeading={"Manage your coupons effectively"}
                        btnText={"Add Coupon"}
                        handleBtn={handleBtn} />
                </div>

                {addAndEditCoupon ?
                    <div className="flex items-center justify-center w-2/4  min-w-fit">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AddAndEditReferrals handleCancel={handleClose} handleSubmit={handleSubmit} data={selectedCoupon} isEdit={isEdit} />
                        </Suspense>
                    </div>
                    :
                    <div className="p-8">
                        {couponData &&
                            <Table columns={columns} data={couponData} pageNation={pageNation} handlePageNation={(value) => fetchAllCoupons(value)} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default index