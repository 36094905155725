
import { auth } from "apis";

export const uploadImage = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "POST",
        url: `/admin/image`,
        options,
        data,
    });
    return res;
}


export const createBlog = async (data) => {
    const res = await auth({
        method: 'POST',
        url: '/admin/blog',
        data
    })
    return res
}

export const UpdateBlog = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: 'PUT',
        url: '/admin/blog',
        options,
        data,
    })
    return res
}

export const getAllBlogs = async (page) => {
    const res = await auth({
        method: 'GET',
        url: `/admin/blogs?page=${page}&limit=10`,
    })
    return res
}

export const fetchLatestBlogs = async (count) => {
    const res = await auth({
        method: 'GET',
        url: `/admin/latest/${count}`,
    })
    return res
}

export const fetchBlogById = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/admin/blog/${id}`,
    })
    return res
}

export const deleteBlogById = async (id) => {
    const res = await auth({
        method: 'DELETE',
        url: `/admin//blog/${id}`,
    })
    return res
}