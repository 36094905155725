import { Steps } from 'components';
import React, { useEffect, useState } from 'react';
// import AdvancedImageCropping from 'components/NEW/image/imageCropping'
// import img from "assets/createNewEvent/images/Picture.png";
import { UpdateBlog } from 'apis/blog';
import { BLOG } from 'common/routes';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Index({ saveData, onSaveSuccess, onSaveFailed, id, blogData, isEdit }) {
    const navigate = useNavigate()
    // const [imgUrl, setImgUrl] = useState(null)
    // const [image, setImage] = useState(null)
    const [data, setData] = useState({
        slug: blogData?.slug_url || null,
        focusKeys: blogData?.focus_keys || [],
        CanonicalUrl: blogData?.canonical_url || null,
        tags: blogData?.tags || [],
        // altText: null,
    })
    // const [advanceCropping, setAdvanceCropping] = useState(false)

    // const fileInputRef = useRef(null);
    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file && file.type.substr(0, 5) === "image") {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImage(file);
    //             setImgUrl(reader.result);
    //             setAdvanceCropping(!advanceCropping)
    //         };
    //         reader.readAsDataURL(file);
    //     } else {
    //         setImage(null);
    //     }
    // };

    // const handleButtonClick = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click();
    //     }
    // };

    // const handleCropComplete = async (croppedFile) => {
    //     setImage(croppedFile);
    //     setImgUrl(URL.createObjectURL(croppedFile));
    //     setAdvanceCropping(!advanceCropping)
    // }

    const handleSaveData = async () => {
        const apiData = {
            current_index: 6,
            id,
            slug: isEdit ? null : data?.slug,
            focusKeys: data?.focusKeys,
            CanonicalUrl: data?.CanonicalUrl,
            tags: data?.tags
        }
        const toastId = toast.loading('Creating blog')
        const res = await UpdateBlog(apiData)
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
            navigate(BLOG.BLOG)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleSaveData()
    }, [saveData])


    const handleAddTag = (event) => {
        const { name, value } = event.target;
        if (event.key === 'Enter' && value.trim() !== '') {
            event.preventDefault();
            setData(prevData => ({
                ...prevData,
                [name]: [...prevData[name], value.trim()],
            }));
            event.target.value = ''; // Clear input after adding tag
        }
    };

    // Function to handle removing a tag or focus key
    const handleRemoveTag = (name, indexToRemove) => {
        setData(prevData => ({
            ...prevData,
            [name]: prevData[name].filter((_, index) => index !== indexToRemove),
        }));
    };

    return (
        <div className="w-full p-6 mx-auto text-black bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} lineActive={true} />
                <Steps number={2} isActive={true} lineActive={true} />
                <Steps number={3} isActive={true} lineActive={true} />
                <Steps number={4} isActive={true} lineActive={true} />
                <Steps number={5} isActive={true} lineActive={true} />
                <Steps number={6} isActive={true} lineActive={false} displayLine={false} />
            </div>

            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">URL (Slug)</label>
                <input value={data?.slug}
                    onChange={(e) => setData(prev => ({ ...prev, slug: e.target.value }))} type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

            {/* Focus Keys Input */}
            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Focus Keys</label>
                <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-lg">
                    {data.focusKeys.map((key, index) => (
                        <div key={index} className="flex items-center space-x-2 px-3 py-1 bg-gray-200 rounded-full text-sm">
                            <span>{key}</span>
                            <button type="button" onClick={() => handleRemoveTag('focusKeys', index)} className="text-gray-500 hover:text-gray-700">
                                &times;
                            </button>
                        </div>
                    ))}
                    <input type="text" name="focusKeys" onKeyDown={handleAddTag}
                        className="flex-grow p-2 border-none focus:ring-0"
                        placeholder="Type and press Enter"
                    />
                </div>
            </div>

            {/* Tags Input */}
            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Tags</label>
                <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-lg">
                    {data.tags.map((tag, index) => (
                        <div key={index} className="flex items-center space-x-2 px-3 py-1 bg-gray-200 rounded-full text-sm" >
                            <span>{tag}</span>
                            <button type="button" onClick={() => handleRemoveTag('tags', index)} className="text-gray-500 hover:text-gray-700">
                                &times;
                            </button>
                        </div>
                    ))}
                    <input type="text" name="tags" onKeyDown={handleAddTag}
                        className="flex-grow p-2 border-none focus:ring-0"
                        placeholder="Type and press Enter"
                    />
                </div>
            </div>

            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">(Canonical URL)</label>
                <input value={data?.CanonicalUrl}
                    onChange={(e) => setData(prev => ({ ...prev, CanonicalUrl: e.target.value }))} type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>



            {/* Image Alt */}
            {/* <div className="mb-6">
                {advanceCropping &&
                    <div className="h-[30vh] w-[50%] flex items-center justify-center">
                        <AdvancedImageCropping image={imgUrl} setPopup={() => setAdvanceCropping(!advanceCropping)} onCropComplete={handleCropComplete} ratio={11 / 6} />
                    </div>
                }
                <div className='flex flex-col w-full'>
                    <label className="block text-base font-semibold">Featured image</label>
                    <div onClick={handleButtonClick} className="h-[280px] w-full my-8 flex flex-col justify-center items-center rounded-[2px] bg-white text-black border-2 border-gray-300 cursor-pointer">
                        {imgUrl ? (
                            <img src={imgUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '280px' }} />
                        ) : (
                            <>
                                <p className='text-[9px] text-gray-500'>supports: PNG, JPG, JPEG</p>
                                <img src={img} alt="" />
                                <p className='text-[9px] text-gray-500'>Click here or drag file to this area to upload</p>
                            </>
                        )}
                        <input type="file" onChange={handleImageChange} style={{ display: 'none' }} ref={fileInputRef} />
                    </div>
                </div>
            </div>


            <div className="mb-6">
                <label className="block mb-2 text-base font-semibold">Alt Text</label>
                <input
                    onChange={(e) => setData(prev => ({ ...prev, altText: e.target.value }))} type="text"
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div> */}
        </div>
    )
}

export default Index
