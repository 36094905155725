import React from 'react';
import { formatDateToDDMMYY } from 'shared/dates';

function ArticleContent({ blogData }) {
    let headingCounter = 0;

    const insertAddIntoContent = (content) => {
        if (!content) return '';

        const adHtml = blogData?.advertisement_center ? `
            <div style="text-align: center; margin: 20px 0;">
                <a href="${blogData.advertisement_center.link}" target="_blank" rel="noopener noreferrer">
                    <img class="w-full h-auto" src="${blogData.advertisement_center.image_url}" alt="${blogData.advertisement_center.alt_text || 'Advertisement'}" />
                </a>
            </div>
        ` : '';

        if (content.includes("center_advertisement")) {
            return content.replace("center_advertisement", adHtml);
        } else {
            return `${content}${adHtml}`;
        }
    };


    const styledHtmlContent = insertAddIntoContent(blogData?.blog_content)
        .replace(/<h1>/g, '<h1 style="font-size: 2.5rem;">')
        .replace(/<h2>/g, () => {
            const id = `heading-${headingCounter++}`;
            return `<h2 id="${id}" style="font-size: 2rem;">`;
        })
        .replace(/<h3>/g, '<h3 style="font-size: 1.75rem;">')
        .replace(/<h4>/g, '<h4 style="font-size: 1.5rem;">')
        .replace(/<h5>/g, '<h5 style="font-size: 1.25rem;">')
        .replace(/<h6>/g, '<h6 style="font-size: 1rem;">');


    return (
        <div className="p-4 text-black">
            <div className="mb-4">
                <h1 className="text-3xl font-bold text-gray-900">{blogData?.title}</h1>
                <p className="text-gray-500">CoupleSquad &bull; {formatDateToDDMMYY(blogData?.createdAt)}</p>
            </div>

            <div className="mb-4" dangerouslySetInnerHTML={{ __html: styledHtmlContent }}></div>
        </div>
    );
}

export default ArticleContent;