import { createOffer, getOfferById, updateOffer } from 'apis/offers/index.js';
import img from 'assets/createNewEvent/images/Picture.png';
import { InputField, MainNavbar, MainSidebar, Map, TextEditor } from "components";
import AdvancedImageCropping from 'components/NEW/image/imageCropping/index.jsx';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { decodeFromBase64 } from 'utils/encodeAndDecode.js';
import { OFFER } from "../../../common/routes.js";

function Index() {
    const navigate = useNavigate()
    const fileInputRefOne = useRef(null);

    const [advanceCropping, setAdvanceCropping] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [imgUrl, setImgUrl] = useState();
    const [image, setImage] = useState(null);

    const [offerDescription, setOfferDescription] = useState('')
    const [offerData, setOfferData] = useState({
        title: '',
        offer: '',
        offer_code: '',
        start_date: '',
        end_date: '',
        latitude: '',
        longitude: '',
    });

    const handleOnChange = (key, value) => {
        setOfferData(prev => ({ ...prev, [key]: value }))
    }
    const handleMapSetLocation = (value) => {
        handleOnChange('latitude', value[0])
        handleOnChange('longitude', value[1])
    }

    const content = [
        { key: 'title', label: 'Offer Title', field: 'input' },
        { key: 'offer', label: 'Offer Percentage', field: 'input' },
        { key: 'offer_code', label: 'Offer Code', field: 'input' },
        { key1: 'start_date', key2: 'end_date', label: 'Date', field: 'doubleInput', type: 'date' },
        { key1: 'latitude', key2: 'longitude', label: 'Co-ordinates', field: 'doubleInput', type: 'number' },
    ];

    const handleButtonClick = (inputRef) => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleCropComplete = (croppedFile) => {
        setImage(croppedFile);
        setImgUrl(URL.createObjectURL(croppedFile));
        setAdvanceCropping(false);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImgUrl(reader.result);
                setAdvanceCropping(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCreateOffer = async () => {
        const toastId = toast.loading('creating Offer')
        const data = {
            ...offerData,
            description: offerDescription,
            image
        }
        const res = await createOffer(data)
        toast.dismiss(toastId)
        if (res?.success) {
            toast.success('Created Offer')
            navigate(OFFER.OFFER)
        }
    }

    const handleFetchOfferById = async (id) => {
        const res = await getOfferById(id)
        setOfferData({
            ...res?.data,
            start_date: new Date(res?.data?.start_date).toISOString().slice(0, 10),
            end_date: new Date(res?.data?.end_date).toISOString().slice(0, 10)
        })
        setImgUrl(res?.data?.image)
        setOfferDescription(res?.data?.description)
    }

    const handleUpdateOffer = async () => {
        const toastId = toast.loading('update Offer')
        const data = {
            ...offerData,
            description: offerDescription,
            image: image ? image : imgUrl
        }
        const res = await updateOffer(data)
        toast.dismiss(toastId)
        if (res?.success) {
            toast.success('Updated Offer')
            navigate(OFFER.OFFER)
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        if (id) {
            handleFetchOfferById(decodeFromBase64(id))
            setIsEdit(true)
        }
    }, [])


    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] min-h-screen h-full w-10/12 text-black">

                    <div className="flex justify-between w-full p-10">
                        <h1>Create Offer</h1>
                    </div>

                    <div className='flex justify-between p-5'>
                        {/* Right Section */}
                        <div className="flex flex-col">
                            {content?.map((item, index) => (
                                <div className="w-full px-2 my-2" key={index}>
                                    <div className='flex items-center justify-between'>
                                        <label className="text-lg">{item?.label}</label>
                                        {item?.field === 'input' &&
                                            <div className="w-[450px] h-[56px]">
                                                <InputField onChange={(value) => handleOnChange(item?.key, value)} value={offerData?.[item?.key]} />
                                            </div>
                                        }
                                        {item?.field === 'doubleInput' &&
                                            <div className='flex flex-col ms-2'>
                                                <div className="min-w-[450px] h-[56px] flex justify-between">
                                                    <InputField onChange={(value) => handleOnChange(item?.key1, value)} type={item?.type} value={offerData?.[item?.key1]} className={'min-w-[210px]'} />
                                                    <InputField onChange={(value) => handleOnChange(item?.key2, value)} type={item?.type} value={offerData?.[item?.key2]} className={'min-w-[210px]'} />
                                                </div>
                                                {item?.label === 'Co-ordinates' && <button onClick={() => setShowMap(!showMap)} className='p-2 px-4 text-white bg-green-500 rounded-lg hover:bg-green-700 w-fit'>Choose from map</button>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}

                            {showMap &&
                                <Map location_latitude={offerData?.latitude} location_longitude={offerData?.longitude} setLocation={(value) => handleMapSetLocation(value)} />
                            }
                        </div>

                        {advanceCropping && (
                            <div className="h-[30vh] w-[50%] flex items-center justify-center">
                                <AdvancedImageCropping
                                    image={imgUrl}
                                    setPopup={() => setAdvanceCropping(false)}
                                    onCropComplete={handleCropComplete}
                                    ratio={4 / 2}
                                />
                            </div>
                        )}

                        {/* Left Section */}
                        <div className="w-[45%]">
                            <label className="block text-base font-semibold">Offer Image</label>
                            <div onClick={() => handleButtonClick(fileInputRefOne)}
                                className="h-[280px] w-full flex flex-col justify-center items-center rounded-[2px] bg-white border-2 border-gray-300 cursor-pointer"
                            >
                                {imgUrl ? (
                                    <img
                                        src={imgUrl}
                                        alt="Preview"
                                        style={{ maxWidth: '100%', maxHeight: '280px' }}
                                    />
                                ) : (
                                    <>
                                        <p className="text-[9px] text-gray-500">
                                            supports: PNG, JPG, JPEG
                                        </p>
                                        <img src={img} alt="" />
                                        <p className="text-[9px] text-gray-500">
                                            Click here or drag file to this area to upload
                                        </p>
                                    </>
                                )}
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    ref={fileInputRefOne}
                                />
                            </div>
                            <div className='mt-5'>
                                <TextEditor setData={setOfferDescription} data={offerDescription} />
                            </div>

                        </div>
                    </div>

                    <div className="flex justify-center gap-5 my-10 text-center">
                        <Link to={OFFER.OFFER} className="px-4 py-2 bg-gray-200 text-gray-700 w-[120px] h-[50px]">Cancel</Link>
                        <button className="px-4 py-2 bg-brandRed text-white w-[120px] h-[50px]" onClick={() => isEdit ? handleUpdateOffer() : handleCreateOffer()}>{isEdit ? 'update' : 'Submit'}</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Index;
