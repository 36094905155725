import React, { useState } from 'react';
import { createUser } from 'apis/users';
import { USERS } from 'common/routes';
import AdvancedImageCropping from 'components/NEW/image/imageCropping';
import DropDown from 'components/NEW/ui/dropDown';
import InputField from 'components/NEW/ui/inputField';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { couplesLookingForOptionsCouples, couplesMartialStatusOPtions, GENDER, interests, relationshipStatusOptions, singlesLookingForOptions, singlesMartialStatusOPtions } from 'shared/constants';

function Index() {

    const navigate = useNavigate()

    const [isCropping, setIsCropping] = useState(false);
    const [currentImageForCropping, setCurrentImageForCropping] = useState(null);
    const [currentIndexForCropping, setCurrentIndexForCropping] = useState(null);
    const [userData, setUserData] = useState({
        name: null,
        email: null,
        phone: null,
        gender: null,
        dob: null,
        relationship_status: null,
        marital_status: null,
        looking_for: null,
        interests: [],
        detailed_bio: null,
        file: [],
        aspect_ratio: '16 / 9',
    });

    const leftSideContent = [
        { key: 'name', label: 'Name', filed: 'input', type: 'text', options: '', },
        { key: 'email', label: 'Email', filed: 'input', type: 'email', options: '', },
        { key: 'phone', label: 'Phone', filed: 'input', type: 'number', options: '', },
        { key: 'dob', label: 'Date Of Birth', filed: 'input', type: 'date', options: '', },
        { key: 'gender', label: 'Gender', filed: 'dropdown', type: '', options: GENDER, },
        { key: 'relationship_status', label: 'RelationShip', filed: 'dropdown', type: '', options: relationshipStatusOptions, },
        { key: 'marital_status', label: 'Marital Status', filed: 'dropdown', type: '', options: userData['relationship_status'] === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions },
        { key: 'looking_for', label: 'LookingFor', filed: 'dropdown', type: '', options: userData['relationship_status'] === 'single' ? singlesLookingForOptions : couplesLookingForOptionsCouples },
        { key: 'interests', label: 'Interests', filed: 'dropdown', type: 'multiple', options: interests, },
    ];

    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }));
    };

    const handleFileChange = (file, index) => {
        const newFiles = [...userData.file];
        newFiles[index] = file;
        setUserData(prev => ({ ...prev, file: newFiles }));
    };



    const removeImage = (indexToRemove) => {
        setUserData(prevState => {
            const updatedFiles = [...prevState.file];
            updatedFiles.splice(indexToRemove, 1);
            return { ...prevState, file: updatedFiles };
        });
    };

    const handleFileInputChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentImageForCropping(reader.result);
                setCurrentIndexForCropping(index);
                setIsCropping(true);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleCreateUser = async () => {
        const formData = new FormData();

        Object.keys(userData).forEach(key => {
            if (key === 'file') {
                userData[key].forEach(file => {
                    formData.append('file', file);
                });
            } else if (Array.isArray(userData[key])) {
                formData.append(key, JSON.stringify(userData[key]));
            } else {
                formData.append(key, userData[key]);
            }
        });

        const toastId = toast.loading('creating user')
        const res = await createUser(formData);
        if (res?.success) {
            navigate(USERS.VIEW_USERS)
            toast.success('Created User Success Fully...')
        }
        toast.dismiss(toastId)
    };




    return (
        <>
            {isCropping && (
                <div className='absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full bg-opacity-75 h-fit'>
                    <div className="w-full max-w-lg p-4 bg-white rounded-lg">
                        <AdvancedImageCropping
                            image={currentImageForCropping}
                            setPopup={() => setIsCropping(false)}
                            onCropComplete={(file) => {
                                handleFileChange(file, currentIndexForCropping);
                                setIsCropping(false);
                            }}
                            circularCrop={false}
                            ratio={4 / 5}
                        />
                    </div>
                </div>
            )}
            <div className='flex flex-col items-center justify-center h-full min-h-screen'>
                <div className="flex justify-center w-full">
                    <div className="w-[46%]">
                        {leftSideContent.map((item, index) => (
                            <div key={index} className="flex items-center justify-between my-4">
                                <h1 className='text-lg font-semibold'>{item.label}</h1>
                                <div className={"h-[56px] w-[70%] flex flex-col justify-center rounded-3xl"}>
                                    {item?.filed === 'dropdown' && (
                                        <DropDown
                                            selectedValue={userData[item.key]}
                                            placeholderValue={item?.label}
                                            options={item?.options}
                                            mode={item?.type}
                                            handleOnChange={(value) => updateState(item?.key, value)}
                                        />
                                    )}
                                    {item?.filed === 'input' && (
                                        <InputField
                                            placeholder={item?.label}
                                            value={userData[item?.key]}
                                            onChange={(value) => updateState(item?.key, value)}
                                            type={item?.type}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="w-[46%] flex flex-col justify-center items-center">
                        <div className="flex flex-col justify-between min-w-[70%] mb-4">
                            <h1 className='mb-2 text-lg font-semibold'>Detailed Bio</h1>
                            <textarea
                                value={userData?.detailed_bio}
                                onChange={(e) => updateState('detailed_bio', e.target.value)}
                                className="min-w-[100%] h-[200px] p-2 border border-gray-300 rounded-lg mb-4">
                            </textarea>
                        </div>

                        {/* File Inputs */}
                        <div className="grid grid-cols-3 gap-10 mt-4">
                            {[...Array(1)].map((_, index) => (
                                <div key={index} className="relative flex flex-col items-center justify-center border border-gray-200" style={{ width: '112px', height: '112px' }}>
                                    {userData.file[index] ? (
                                        <div className="relative">
                                            <img src={URL.createObjectURL(userData.file[index])} alt={`Preview ${index + 1}`} className="max-w-[112px] max-h-[112px] outline-none border-none" /> {/* Corrected property name from 'files' to 'file' */}
                                            <button onClick={() => removeImage(index)} className="absolute px-2 py-1 text-white rounded-full bg-brandRed -top-5 -right-7">X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="file"
                                                accept='image'
                                                onChange={(e) => handleFileInputChange(e, index)}
                                                id={`file-input-${index}`}
                                                className="hidden mb-2 cursor-pointer"
                                            />
                                            <label htmlFor={`file-input-${index}`} className="text-sm text-gray-500 cursor-pointer">Choose an image</label>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

                <div className="flex items-center gap-10 mt-10">
                    <button className='p-2 px-6 text-white bg-brandRed' onClick={handleCreateUser}>Submit</button>
                    <Link to={USERS.VIEW_USERS}>
                        <button className='p-2 px-6 text-black border border-black'>Cancel</button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Index;
