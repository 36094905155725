// Events
export const EVENTS = {
    EVENTS:"/events",
    CREATE_AND_EDIT_EVENT_BASIC: "/event/basic",
    CREATE_AND_EDIT_EVENT_ADVANCE: "/event/advance",
    VIEW_EVENT: "/event/view",
    EDIT_EVENT: "/event/edit",
    VIEW_EVENT_PARTICIPANT: "/event/participant",
}

export const USERS = {
    VIEW_USERS: "/users",
    EDIT_USER_DETAILS:'/user/details/edit/:id',
    DELETED_USERS: "/users/deleted",
    CREATE_USERS: "/users/create",
}

export const BLOG = {
    BLOG: '/blog',
    CREATE_BLOG: '/blog/create/:id?',
    VIEW_BLOG: '/blog/view/:id',
}

export const AFFILIATE_COUPONS = {
    // Track affiliate coupons
    TRACK_AFFILIATE_COUPON: '/affiliateCoupon/track/:id',
}

export const OFFER = {
    OFFER: '/offer',
    CREATE_OFFER:'/offer/create'
}

export const HOTSPOT = {
    HOTSPOT: '/hotspot',
    CREATE_HOTSPOT:'/hotspot/create'
}

export const AFFINITY_SCORE = {
    AFFINITY_SCORE: '/affinity',
    CREATE_AFFINITY_SCORE:'/affinity/create'
}

export const RELIGION_AND_CAST = {
    RELIGION: '/religion',
    CAST:'/cast'
}

export const SIDEBAR = {
    // Home
    HOME_OVERVIEW: '/',

    // EVENT
    EVENTS: EVENTS.EVENTS,
    EVENTS_CONNECTIONS:"/event/connection",
    EVENTS_COMMENTS:"/event/comments",
    EVENTS_CATEGORY:"/event/category",
    EVENTS_LOCATION:"/event/location",
    EVENTS_TAG:"/event/tag",
    EVENTS_HOST: "/event/host",

    // USER
    VIEW_USERS: USERS.VIEW_USERS,
    DELETED_USERS: USERS.DELETED_USERS,

    // PLAN and LIMITATION
    PLANS:'/plan',
    PLAN_CATEGORY: '/plan/category',
    PLAN_LIMITATION: '/plan/limitation',
    PLAN_COIN: '/plan/coin',
    
    // Coupon
    COUPON: '/coupon',

    // Notification
    NOTIFICATION: '/notification',
    
    // Verification
    VERIFICATION:'/verification',
    
    // AppUpdate
    APP_UPDATE: '/appUpdate',
    
    // Blog
    BLOG:BLOG.BLOG,

    // Affiliate Coupons
    AFFILIATE_COUPONS: '/affiliateCoupon',
    
    // Offer
    OFFER: OFFER.OFFER,
    
    // HOTSPOT
    HOTSPOT:HOTSPOT.HOTSPOT,

    // Affinity Score
    AFFINITY_SCORE: AFFINITY_SCORE.AFFINITY_SCORE,
    
    // RELIGION_AND_CAST Mangement
    RELIGION:RELIGION_AND_CAST.RELIGION,
    CAST:RELIGION_AND_CAST.CAST,
}