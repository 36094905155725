import React from 'react'

function Index({ number, isActive, lineActive = false, displayLine = true }) {
    return (
        <div className="flex items-center">
            <div
                className={`rounded-full h-8 w-8 flex items-center justify-center ${isActive ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-400'
                    }`}
            >
                {number}
            </div>
            {displayLine &&
                <div className={`w-10 h-1 mx-2 ${lineActive ? 'bg-red-500' : 'bg-gray-200'}`}></div>
            }

        </div>
    );
};

export default Index