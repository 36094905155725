import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { DropDown } from "components"
import { physicalStatusOptions } from '../../../../../../shared/constants';

function Index({ data, isEdited }, ref) {

    const height = Array.from({ length: 250 - 140 + 1 }, (_, index) => 140 + index);
    const weight = Array.from({ length: 150 - 40 + 1 }, (_, index) => 40 + index);
    const [userData, setUserData] = useState({})

    const content = [
        { label: "Height", value: 'height', options: height.map(h => ({ label: `${h} cm`, value: h })) },
        { label: "Weight", value: 'weight', options: weight.map(h => ({ label: `${h} kg`, value: h })) },
        { label: "Physical Status", value: 'physical_status', options: physicalStatusOptions },

    ]

    const updateState = (key, value) => {
        isEdited()
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (data) {
            setUserData({
                height: data?.height || null,
                weight: data?.weight || null,
                physical_status: data?.physical_status || null,
            })
        }
    }, [data])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Physical Details</h1>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-fit">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='text-lg font-semibold'>{item.label}</h1>
                                <div className="w-[70%]">
                                    <DropDown
                                        selectedValue={userData?.[item?.value]}
                                        options={item.options}
                                        handleOnChange={(value) => updateState(item.value, value)}
                                    />

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
