import React from 'react';
import phoneIcon from 'assets/events/icons/phone.png'
import emailIcon from 'assets/events/icons/email.png'
import verification from 'assets/icons/verified.png'


function Index({ serialNo, data, onConfirm, handleCancel, btnText, btnColor, viewUser, isVerified }) {

    return (
        <div className="w-full p-4 overflow-hidden text-center bg-white shadow-lg rounded-3xl">
            <div className="flex gap-2 mt-4 relative">
                <div className="mb-4 text-sm font-semibold text-gray-500">{serialNo}</div>
                <div onClick={viewUser} className="relative w-full h-40 overflow-hidden rounded-3xl">
                    {data?.photo ? (
                        <img className="object-cover w-full h-full cursor-pointer"
                            src={data?.photo} alt="Card" />
                    ) : (
                        <div className="flex items-center justify-center w-full h-full text-center bg-gray-300 cursor-pointer">
                            No Profile Pic
                        </div>
                    )}
                    {/* Verification icon positioned over the image */}
                    {isVerified &&
                        <img src={verification} alt="Verified"
                            className="absolute bottom-2 right-2 w-7 h-7  rounded-full p-1 bg-white" />
                    }
                </div>
            </div>

            <div className="mt-4 text-xl font-medium">
                {data?.name}
            </div>
            <p className="flex items-center mt-2 text-gray-600">
                <img src={phoneIcon} alt="phoneIcon" className='me-2' />
                {data?.phone}
            </p>
            <p className="flex items-center mt-2 text-gray-600 me-2" title={data.email}>
                <img src={emailIcon} alt="emailIcon" className="me-2" />
                {data?.email ? data.email : "No Email Provided"}
            </p>

            <div className="flex items-center justify-around">
                {onConfirm &&
                    <button
                        onClick={onConfirm}
                        className={`mt-4 bg-${btnColor}  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}>
                        {btnText}
                    </button>
                }
                {handleCancel &&
                    <button
                        onClick={handleCancel}
                        className="mt-4 bg-brandRed  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Cancel
                    </button>
                }
            </div>
        </div>
    );
}

export default React.memo(Index)
