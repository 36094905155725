import { auth } from "apis";


export const createOffer = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "POST",
        url: `/admin/offer`,
        options,
        data
    });
    return res;
};

export const getOffersByStatus = async (page,status) => {
    const res = await auth({
        method: 'GET',
        url: `/admin/offers?page=${page}&limit=10&status=${status}`,
    })
    return res
}

export const updateOffer = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: 'PUT',
        url: `/admin/offer`,
        options,
        data,
    })
    return res
}

export const getOfferById = async (id) => {
    const res = await auth({
        method: 'GET',
        url: `/admin/offer/${id}`,
    })
    return res
}

export const updateOfferStatus = async (id,data) => {
    const res = await auth({
        method: 'PUT',
        url: `/admin/offer/status/${id}`,
        data
    })
    return res
}


