import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { InputField, DropDown } from "components"
import { relationWithParent } from '../../../../../../shared/constants'

function Index({ data, isEdited }, ref) {

    const [userData, setUserData] = useState({})

    const content = [
        { label: "Parent Name", value: 'parent_name', isDropdown: false },
        { label: "RelationShip", value: 'relationship', isDropdown: true, options: relationWithParent },
        { label: "Contact Number", value: 'contact_number', isDropdown: false },
    ]

    const updateState = (key, value) => {
        isEdited()
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (data) {
            setUserData({
                parent_name: data?.parent_name,
                relationship: data?.relationship,
                contact_number: data?.contact_number,
            })
        }
    }, [data])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Family Details</h1>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-fit">
                        {content.map((item, index) => {
                            const useDropdown = item.isDropdown
                            return (
                                <div key={index} className="flex items-center justify-between my-4">
                                    <h1 className='text-lg font-semibold'>{item.label}</h1>
                                    <div className={useDropdown ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl my-2" : "w-[70%]"}>
                                        {useDropdown ? (
                                            <DropDown
                                                selectedValue={userData[item.value]}
                                                placeholderValue={""}
                                                options={item.options}
                                                handleOnChange={(value) => updateState(item.value, value)}
                                            />
                                        ) : (
                                            <InputField
                                                value={userData[item.value]}
                                                onChange={(value) => updateState(item.value, value)}
                                                type={item.type}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
