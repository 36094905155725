import { EventParticipantsCard, ManagementSecMainHeader } from 'components'
import React, { lazy, Suspense, useEffect, useState } from 'react'

import { cancelEventParticipant, getEventConfirmedParticipantsById, getEventParticipantsById, getPreBookedParticipantsById, updateEventParticipantStatus } from 'apis/events'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { decodeFromBase64, encodeToBase64 } from 'utils/encodeAndDecode'
import { USERS } from '../../../common/routes'

const EventNotification = lazy(() => import("components/NEW/popups").then(module => ({ default: module.EventNotificationPopup })));



function Index() {

    const { id } = useParams()
    const [currentTab, setCurrentTab] = useState('participants')
    const [participants, setParticipants] = useState([])
    const [showNotificationPopup, setShowNotificationPopup] = useState(false)
    const [pageNation, setPageNation] = useState({
        current_page: 1,
        next_page: null,
    })


    const fetchAllEventParticipants = async (page) => {
        const res = await getEventParticipantsById(decodeFromBase64(id), page)
        setParticipants(prev => ([...prev, ...res?.data]))
        setPageNation({ ...res?.pagination })
    }

    const fetchAllEventConfirmedParticipants = async (page) => {
        const res = await getEventConfirmedParticipantsById(decodeFromBase64(id), page)
        setParticipants(prev => ([...prev, ...res?.data]))
        setPageNation({ ...res?.pagination })
    }

    const fetchAllPreBookedParticipantsById = async (page) => {
        const res = await getPreBookedParticipantsById(decodeFromBase64(id), page)
        setParticipants(prev => ([...prev, ...res?.data]))
        setPageNation({ ...res?.pagination })
    }

    const handleUpdateEventParticipantsStatus = async (data, status) => {
        const res = await updateEventParticipantStatus(data, status)
        if (res?.success) {
            toast.success("Updated event Participant Status")
            setParticipants([])
            if (currentTab === 'participants') {
                fetchAllEventParticipants(pageNation.current_page)
            } else {
                fetchAllEventConfirmedParticipants(pageNation.current_page)
            }
        }
    }

    const handleSwitchTab = (tab) => {
        setPageNation({ current_page: 1, next_page: null })
        setParticipants([])
        setCurrentTab(tab)
        if (tab === 'participants') {
            fetchAllEventParticipants(1)
        } else if (tab === 'confirmed') {
            fetchAllEventConfirmedParticipants(1)
        } else if (tab === 'preBooked') {
            fetchAllPreBookedParticipantsById(1)
        }
    }

    const handleView = (id) => {
        const url = USERS.EDIT_USER_DETAILS.replace(':id', encodeToBase64(id));
        window.open(url, '_blank');
    };

    const handleCancelParticipant = async (eventId, userId) => {  // Remove Event Participants
        const toastId = toast.loading('Removing the user')
        const data = { userId: userId }
        const res = await cancelEventParticipant(eventId, data)
        if (res.success) fetchAllEventParticipants(1)
        toast.dismiss(toastId)
    }

    useEffect(() => {
        fetchAllEventParticipants(1)
    }, [])

    return (
        <div className='flex flex-col h-screen'>
            {showNotificationPopup &&
                <div className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50">
                    <div className="relative w-[40%] max-h-full p-6 overflow-auto bg-white rounded-lg">
                        <Suspense fallback={<div>Loading...</div>}>
                            <EventNotification handleCancel={() => setShowNotificationPopup(false)} />
                        </Suspense>
                    </div>
                </div>
            }

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Event"}
                        subHeading={"Manage your events effectively"}
                        btnText={"Send Notification"}
                        handleBtn={() => setShowNotificationPopup(true)}
                    />

                    <div className="flex gap-5">
                        <div className={`${currentTab === 'participants' ? 'text-brandRed border-b-2 border-brandRed pb-2' : 'text-black'} font-bold text-lg cursor-pointer`} onClick={() => handleSwitchTab('participants')}>Participants</div>
                        <div className={`${currentTab === 'confirmed' ? 'text-brandRed border-b-2 border-brandRed pb-2' : 'text-black'} font-bold text-lg cursor-pointer`} onClick={() => handleSwitchTab('confirmed')}> Confirmed</div>
                        <div className={`${currentTab === 'preBooked' ? 'text-brandRed border-b-2 border-brandRed pb-2' : 'text-black'} font-bold text-lg cursor-pointer`} onClick={() => handleSwitchTab('preBooked')}>Pre Booked</div>
                    </div>
                    <div className="flex flex-wrap gap-5">
                        {participants?.map((item, index) => (
                            <div className="w-[250px] my-5" key={item?.id}>
                                <EventParticipantsCard
                                    serialNo={index + 1}
                                    data={item?.user}
                                    onConfirm={currentTab === 'preBooked' ? false : () => handleUpdateEventParticipantsStatus({ userId: item?.user.id, eventId: item?.eventId }, currentTab === 'confirmed' ? false : true)}
                                    btnColor={currentTab === 'confirmed' ? 'brandRed' : 'blue-500'}
                                    btnText={currentTab === 'confirmed' ? 'Confirmed' : 'Confirm'}
                                    viewUser={() => handleView(item?.user?.id)}
                                    isVerified={item?.user?.verification === 'verified' ? true : false}
                                    handleCancel={currentTab === 'preBooked' ? false : () => handleCancelParticipant(item?.eventId, item?.user?.id)}
                                />
                            </div>
                        ))}
                    </div>
                    {pageNation.next_page &&
                        <div className="flex items-center justify-center my-10 text-blue-500" onClick={() => currentTab === 'participants' ? fetchAllEventParticipants(pageNation.next_page) : (currentTab === 'confirmed' ? fetchAllEventConfirmedParticipants(pageNation.next_page) : fetchAllPreBookedParticipantsById(pageNation.next_page))}>
                            see more
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default Index