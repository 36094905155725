import React, { lazy, Suspense, useEffect, useState } from 'react'
import { toast } from "react-toastify"
import { createHost, getAllHosts, updateHost } from '../../../apis/events'

import { ManagementSecMainHeader, TableWithoutPagination } from 'components'

const AddAndEditHost = lazy(() => import("components").then(module => ({ default: module.AddAndEditHost })))


function Index() {

    const [showHostModule, setShowHostModule] = useState(false)
    const [hostData, setHostData] = useState()
    const [selectedHost, setSelectedHost] = useState()
    const [isEdit, setIsEdit] = useState(false)

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Host Name', accessor: 'name' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Phone', accessor: 'phone' },
        {
            Header: 'Photo',
            accessor: 'photo',
            Cell: ({ value }) => (
                <>
                    {value ? (
                        <img src={value} alt="Host" className="w-16 h-16 rounded-[2px]" />
                    ) : (
                        <span>No Image</span>
                    )}
                </>
            )
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Inactive
                        </span>
                    }
                </>

            )
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )

        },
    ];


    const handleEdit = async (row) => {
        setIsEdit(true)
        setSelectedHost(row)
        setShowHostModule(!showHostModule)
    };

    const fetchEventHosts = async () => {
        const res = await getAllHosts()
        setHostData(res?.data)
    }

    useEffect(() => {
        fetchEventHosts()
    }, [])

    const handleSubmit = async (value) => {
        const operation = isEdit ? 'updating' : 'creating';
        const successMessage = isEdit ? 'Updated Host successfully...' : 'Created Host successfully...';
        const toastId = toast.loading(`${operation} Host`);
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { status, ...restOfObject } = value;
        try {
            const res = isEdit ? await updateHost(selectedHost.id, value) : await createHost(restOfObject);
            toast.dismiss(toastId);
            if (res.success) {
                toast.success(successMessage);
                if (isEdit) {
                    setIsEdit(false);
                }
                setShowHostModule(false);
                fetchEventHosts();
            }
        } catch (error) {
            console.error("Error during Host operation", error);
            toast.error(`Failed to ${operation.toLowerCase()} Host.`);
            toast.dismiss(toastId);
        }
    };



    const handleClose = () => {
        setShowHostModule(!showHostModule)
        setIsEdit(false)
        setSelectedHost(null)
    }

    return (
        <>
            <div className={`flex flex-col h-full`}>
                {showHostModule && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50">
                        <div className="relative max-h-full p-6 overflow-auto bg-white rounded-lg w-[34%]">
                            <Suspense fallback={<div>Loading...</div>}>
                                <AddAndEditHost handleClose={handleClose} handleSubmit={handleSubmit} data={selectedHost} isEdit={isEdit} />
                            </Suspense>
                        </div>
                    </div>
                )}

                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] w-full">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Event"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Add Host"}
                            handleBtn={() => setShowHostModule(!showHostModule)} />
                    </div>

                    {/* Table */}
                    <div className="p-8 max-w-[100%]">
                        {hostData &&
                            <TableWithoutPagination columns={columns} data={hostData} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index