import React, { useState } from 'react';
import { capitalize } from 'shared/textConverter';

function Index({ handleShowFilter, handleFilterData, }) {

    const [selectedCheckbox, setSelectedCheckbox] = useState(localStorage.getItem('userVerificationFilter'))
    const dropDownContent = ['requested', 'verified', 'rejected', 'waitlist']

    const handleSelectedItem = (item) => {
        setSelectedCheckbox(item)
        localStorage.setItem('userVerificationFilter', item)

    }

    const handleCancel = () => {
        localStorage.removeItem('userVerificationFilter')
        handleShowFilter()
    }


    return (
        <div className="w-[95%] h-full flex flex-col items-center mx-auto mb-4">
            <div className="flex items-center justify-between w-full m-2 mt-20 text-xl font-semibold">
                <h1>Filter</h1>
            </div>


            <div className="flex flex-col items-center flex-grow w-full">
                {dropDownContent.map((item, index) => (
                    <div key={index} className="w-[90%] flex justify-between">
                        <div className="flex items-center my-5 mt-6">
                            <input type="checkbox" className="mr-2 h-[15px] w-[15px]" checked={selectedCheckbox === item}
                                onChange={() => handleSelectedItem(item)} />
                            <span className='text-base'>{capitalize(item)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex items-center justify-center w-full pb-8 mt-auto">
                <button className='w-[110px] h-[40px] border-2 me-3' onClick={handleCancel}>Cancel</button>
                <button className='w-[110px] h-[40px] bg-brandRed text-white' onClick={() => {
                    handleFilterData(selectedCheckbox)
                    handleShowFilter()
                }}>Filter</button>
            </div>
        </div>

    );
}

export default React.memo(Index)
