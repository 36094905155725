/* eslint-disable unused-imports/no-unused-vars */
import { getUserDetails, getUserFilter, updateUserBasicAndAdvanceFilter, updateUserDetails } from 'apis/users';
import avatar from 'assets/icons/avatar.png';
import verification from 'assets/icons/verified.png';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { generateProfilePDF } from './basicInfo/pdfDownloader';
import { initializeUserBasicAndAdvanceFilterState, initializeUserDetailState, structuredApiData, structuredUserFilterApiData } from './helper';

import UserBasicInfo from './basicInfo';
import DietAttributes from './dietAttributes';
import EducationDetails from './educationDetails';
import FamilyDetails from './familyDetails';
import GroomsLocation from './groomsLocation';
import LanguageAndIntrusts from './languageAndIntrusts';
import PhysicalDetails from './physicalDetails';

import AdvanceFilter from './filter/advanceFilter';
import BasicFilter from './filter/basicFilter';



function Index({ decodedId, edit = false }) {
    const [userData, setUserData] = useState()
    const [basicInfoIsEdited, setBasicInfoIsEdited] = useState(false)
    const basicInfoEditedRef = useRef(false);
    const [userBasicAndAdvanceFilterData, setUserBasicAndAdvanceFilterData] = useState()


    // Refs to access child data
    const basicDetailsRef = useRef();
    const physicalDetailsRef = useRef();
    const dietAttributesRef = useRef();
    const groomsLocationRef = useRef();
    const educationDetailsRef = useRef();
    const familyDetailsRef = useRef();
    const languageAndIntrustsRef = useRef();

    const basicFilterDetailsRef = useRef();
    const advanceFilterDetailsRef = useRef();

    // API CALLS GET AND UPDATE
    // GET
    const fetchUserDetails = async () => {
        const res = await getUserDetails(decodedId)
        const data = res?.data[0]
        const initializedState = initializeUserDetailState(data)
        setUserData({ ...initializedState })
    }

    const fetchUserFilter = async () => {
        const res = await getUserFilter(decodedId)
        const data = res?.data
        const initializedState = initializeUserBasicAndAdvanceFilterState(data?.basic, data?.advanced)
        setUserBasicAndAdvanceFilterData({ ...initializedState })
    }

    // UPDATE
    const updateUser = async () => {

        // Fetch updated data from all child components
        const updatedBasicDetails = basicDetailsRef?.current?.getData();
        const updatedPhysicalDetails = physicalDetailsRef?.current?.getData();
        const updatedDietAttributes = dietAttributesRef?.current?.getData();
        const updatedGroomsLocation = groomsLocationRef?.current?.getData();
        const updatedEducationDetails = educationDetailsRef?.current?.getData();
        const updatedFamilyDetails = familyDetailsRef?.current?.getData();
        const updatedLanguageAndIntrusts = languageAndIntrustsRef?.current?.getData();

        const updatedData = {
            ...updatedBasicDetails,
            ...updatedPhysicalDetails,
            ...updatedDietAttributes,
            ...updatedGroomsLocation,
            ...updatedEducationDetails,
            ...updatedFamilyDetails,
            ...updatedLanguageAndIntrusts,

        }
        const data = structuredApiData(updatedData)
        const res = await updateUserDetails(decodedId, data)
        if (res?.success) {
            toast.success('updated user Details')
            fetchUserDetails()
        }
    }

    const updateUserFilter = async () => {

        const updatedBasicFilterDetails = basicFilterDetailsRef.current.getData();
        const updatedAdvanceFilterDetails = advanceFilterDetailsRef.current.getData();
        const updatedData = {
            basicDetails: updatedBasicFilterDetails,
            advancedDetails: updatedAdvanceFilterDetails,
        }

        const data = structuredUserFilterApiData(updatedData)
        const res = await updateUserBasicAndAdvanceFilter(decodedId, data)
        if (res?.success) {
            toast.success('updated Filter Details')
            fetchUserFilter()
        }
    }



    useEffect(() => {
        fetchUserDetails();
        fetchUserFilter();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const downloadPdf = () => {
        generateProfilePDF(decodedId)
    };


    return (
        <div>
            <div className="flex justify-between">
                <div className="">
                    <h1 className='flex pt-5 text-xl font-semibold'>Basic Info({userData?.referrer?.name})
                        <h1 onClick={downloadPdf} className='p-2 px-4 text-base text-white rounded-lg cursor-pointer ms-2 bg-brandRed'>Download</h1>
                    </h1>

                </div>
                <div className="relative inline-block">
                    <img src={userData?.images[0]?.url ? userData?.images[0]?.url : avatar} alt="user image" className="w-[70px] h-[80px]" />
                    {userData?.verified === 'verified' &&
                        <img src={verification} alt="verified icon" className="absolute bottom-1 -left-3 w-[30px] h-[30px]" />
                    }
                </div>
            </div>
            <div>
                <UserBasicInfo ref={basicDetailsRef} data={userData} decodedId={decodedId} isEdited={() => setBasicInfoIsEdited(true)} />

                <div className='flex justify-between'>
                    <div className="w-[46%]">
                        <PhysicalDetails ref={physicalDetailsRef} data={userData} isEdited={() => setBasicInfoIsEdited(true)} />
                    </div>
                    <div className="w-[46%]">
                        <DietAttributes ref={dietAttributesRef} data={userData} isEdited={() => setBasicInfoIsEdited(true)} />
                    </div>
                </div>

                <div className='flex justify-between'>
                    <div className="w-[46%]">
                        <GroomsLocation ref={groomsLocationRef} data={userData} isEdited={() => setBasicInfoIsEdited(true)} />
                    </div>
                    <div className="w-[46%]">
                        <EducationDetails ref={educationDetailsRef} data={userData} isEdited={() => setBasicInfoIsEdited(true)} />
                    </div>
                </div>

                <div className='flex justify-between'>
                    <div className="w-[46%]">
                        <FamilyDetails ref={familyDetailsRef} data={userData} isEdited={() => setBasicInfoIsEdited(true)} />
                    </div>
                    <div className="w-[46%]">
                        <LanguageAndIntrusts ref={languageAndIntrustsRef} data={userData} isEdited={() => setBasicInfoIsEdited(true)} />
                    </div>
                </div>

                <div className='flex justify-between'>
                    <div className="w-[46%]">
                        <BasicFilter ref={basicFilterDetailsRef} data={userBasicAndAdvanceFilterData?.basicDetails} />
                    </div>
                    <div className="w-[46%]">
                        <AdvanceFilter ref={advanceFilterDetailsRef} data={userBasicAndAdvanceFilterData?.advancedDetails} userDetails={userData} />
                    </div>
                </div>
            </div>

            {edit &&
                <div className="flex items-center justify-center gap-5">
                    <button onClick={updateUser} className='flex items-center justify-center p-3 px-4 mt-5 text-white bg-brandRed'>Update Details</button>
                    <button onClick={updateUserFilter} className='flex items-center justify-center p-3 px-4 mt-5 text-white bg-brandRed'>Update User Filter</button>
                </div>
            }
        </div>
    );
}

export default Index;