import { DropDown, ToggleBtn } from 'components';
import React from 'react';
import { eventStatus } from 'shared/constants';

const EventCard = ({
    eventImg,
    eventName,
    eventType,
    totalBookings,
    status,
    connection,
    eventCategory,

    eventTime,
    eventDate,
    totalSlots,
    totalPrebooked,
    location,
    totalParticipants,
    totalComments,

    handleDelete,
    handleDuplicate,
    handleEdit,
    handleViewEventParticipants,
    handleViewEventComments,
    handleUpdateEventConnectionStatus,
    handleUpdateEventStatus,
    handleViewEvent,
    updateEventLink
}) => {

    return (
        <div className="border rounded-lg shadow-lg w-[330px] p-4 bg-white">
            {/* Image Section */}
            <div className="relative" onClick={handleViewEvent}>
                <img
                    src={eventImg || "https://via.placeholder.com/400x200"}
                    alt="Event"
                    className="object-cover w-full h-48 rounded-t-lg cursor-pointer"
                />
            </div>

            {/* Event Information */}
            <div className="p-4">
                {/* Avatar & Status Section */}
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <p className="text-sm cursor-pointer hover:text-blue-500 hover:underline">Total Bookings : {totalBookings}</p>
                    </div>

                    {/* Status Dropdown */}
                    <div className='flex items-center cursor-pointer w-28 h-9'>
                        <DropDown
                            selectedValue={status}
                            options={eventStatus}
                            handleOnChange={(value) => handleUpdateEventStatus(value)}
                        />
                    </div>
                </div>

                {/* Toggle Switch */}
                <div className="flex items-center justify-between p-3 mt-4 border border-gray-300 rounded-md cursor-pointer">
                    <p className="text-sm font-medium">Connection</p>
                    <ToggleBtn checked={connection} onChange={handleUpdateEventConnectionStatus} />
                </div>

                {/* Event Name  */}
                <div className="mt-4">
                    <h3 className="text-lg font-semibold">{eventName}</h3>

                    <div className="mt-2">
                        <div className="text-lg">Event Category</div>
                        <div className='flex gap-2'>
                            {eventCategory?.map((item) => (
                                <h3 key={item} className="px-2 py-1 text-xs font-medium text-blue-600 bg-blue-100 rounded">{item?.name}</h3>
                            ))}
                        </div>
                    </div>

                </div>


                <div className="flex justify-between mt-4">
                    {/* Left section */}
                    <div className="flex flex-col gap-2">
                        <span className="px-2 py-1 text-xs font-medium rounded">Event Time : {eventTime}</span>
                        <span className="px-2 py-1 text-xs font-medium rounded">Event Date : {eventDate}</span>
                        <span className="px-2 py-1 text-xs font-medium rounded">Total Slots : {totalSlots}</span>
                    </div>
                    {/* Right section */}
                    <div className="flex flex-col gap-2">
                        <span className="px-2 py-1 text-xs font-medium rounded">Event Type :
                            <span className={`${eventType === 'Online' ? 'text-green-600 underline cursor-pointer' : ''}`}
                                onClick={() => eventType === 'Online' && updateEventLink()}
                            >
                                {eventType}
                            </span>
                        </span>
                        <span className="px-2 py-1 text-xs font-medium rounded">Prebooked : {totalPrebooked}</span>
                        <span className="px-2 py-1 text-xs font-medium rounded">Location : {location}</span>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-between mt-4">
                    <button onClick={handleViewEventParticipants} className="px-4 py-2 text-sm border border-black rounded-lg">Participants ({totalParticipants})</button>
                    <button onClick={handleViewEventComments} className="px-4 py-2 text-sm text-white bg-black rounded-lg">Comments ({totalComments})</button>
                </div>

                {/* Footer Buttons */}
                <div className="flex justify-between mt-4 space-x-2">
                    <button onClick={handleDelete} className="w-full px-4 py-2 text-white bg-red-500 rounded-lg">Delete</button>
                    <button onClick={handleDuplicate} className="w-full px-4 py-2 text-white bg-green-500 rounded-lg">Duplicate</button>
                    <button onClick={handleEdit} className="w-full px-4 py-2 text-white bg-blue-500 rounded-lg">Edit</button>
                </div>
            </div>
        </div>
    );
};

export default EventCard;
