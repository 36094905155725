import React, { useEffect, useState } from 'react'
import { MainNavbar, MainSidebar } from 'components'

function Index({ children }) {
    const [isCollapsed, setIsCollapsed] = useState(
        () => JSON.parse(localStorage.getItem('isSidebarCollapsed')) || false
    );

    useEffect(() => {
        // Ensure that the isCollapsed state is correctly stored whenever it changes
        localStorage.setItem('isSidebarCollapsed', JSON.stringify(isCollapsed));
    }, [isCollapsed]);

    // Handle collapse toggle from the sidebar
    const handleSidebarToggle = () => {
        setIsCollapsed(prev => !prev);
    };

    return (
        <div>
            <div className='flex flex-col h-full min-h-screen'>

                {/* Header */}
                <div className="min-h-[120px] w-full">
                    <MainNavbar />
                </div>

                <div className="flex">
                    {/* SideBar */}
                    <div className={`h-screen transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-2/12'}`}>
                        <MainSidebar isCollapsed={isCollapsed} onToggle={handleSidebarToggle} />
                    </div>

                    {/* Page Content */}
                    <div className={`transition-all duration-300 ${isCollapsed ? 'w-[calc(100%-4rem)]' : 'w-10/12'}`}>
                        {children}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Index