import { UpdateBlog } from 'apis/blog';
import img from 'assets/createNewEvent/images/Picture.png';
import { Steps } from 'components';
import AdvancedImageCropping from 'components/NEW/image/imageCropping';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

function Index({ saveData, onSaveSuccess, onSaveFailed, id, blogData }) {
    const [imgRatio, setImageRatio] = useState()
    const [imgUrl, setImgUrl] = useState({
        imageOne: blogData?.advertisement_right_side?.image_url || null,
        imageTwo: blogData?.advertisement_center?.image_url || null,
    });
    const [image, setImage] = useState({
        imageOne: null,
        imageTwo: null,
    });
    const [alt, setAlt] = useState({
        altOne: blogData?.advertisement_right_side?.alt_text || '',
        altTwo: blogData?.advertisement_center?.alt_text || '',
    });
    const [link, setLink] = useState({
        linkOne: blogData?.advertisement_right_side?.link || '',
        linkTwo: blogData?.advertisement_center?.link || '',
    });
    const [advanceCropping, setAdvanceCropping] = useState(false);
    const [croppingImageKey, setCroppingImageKey] = useState(null);

    const fileInputRefOne = useRef(null);
    const fileInputRefTwo = useRef(null);

    const handleImageChange = (e, imageKey) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage((prev) => ({ ...prev, [imageKey]: file }));
                setImgUrl((prev) => ({ ...prev, [imageKey]: reader.result }));
                setCroppingImageKey(imageKey);
                setAdvanceCropping(true);
            };
            reader.readAsDataURL(file);
        } else {
            setImage((prev) => ({ ...prev, [imageKey]: null }));
        }
    };

    const handleButtonClick = (inputRef) => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleCropComplete = async (croppedFile) => {
        setImage((prev) => ({ ...prev, [croppingImageKey]: croppedFile }));
        setImgUrl((prev) => ({
            ...prev,
            [croppingImageKey]: URL.createObjectURL(croppedFile),
        }));
        setAdvanceCropping(false);
    };

    const handleSaveData = async () => {
        const data = {
            current_index: 4,
            id,
            advertisement_right_link: link?.linkOne,
            advertisement_right_alt_text: alt?.altOne,
            advertisement_right_image: image?.imageOne,
            advertisement_center_link: link?.linkTwo,
            advertisement_center_alt_text: alt?.altTwo,
            advertisement_center_image: image?.imageTwo,
        }
        const toastId = toast.loading('Creating blog')
        const res = await UpdateBlog(data)
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleSaveData()
    }, [saveData])

    return (
        <div className="w-full p-6 mx-auto text-black bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} lineActive={true} />
                <Steps number={2} isActive={true} lineActive={true} />
                <Steps number={3} isActive={true} lineActive={true} />
                <Steps number={4} isActive={true} />
                <Steps number={5} />
                <Steps number={6} displayLine={false} />
            </div>

            {/* Image Alt */}
            <div className="mb-6">
                {advanceCropping && (
                    <div className="h-[30vh] w-[50%] flex items-center justify-center">
                        <AdvancedImageCropping
                            image={imgUrl[croppingImageKey]}
                            setPopup={() => setAdvanceCropping(false)}
                            onCropComplete={handleCropComplete}
                            ratio={imgRatio}  // 15/20
                        />
                    </div>
                )}
                <div className="flex items-center justify-between w-full">
                    <div className="w-[45%]">
                        <label className="block text-base font-semibold">
                            Advertisement One (Right Side)
                        </label>
                        <div
                            onClick={() => {
                                handleButtonClick(fileInputRefOne)
                                setImageRatio(15 / 20)
                            }}
                            className="h-[280px] w-full my-8 flex flex-col justify-center items-center rounded-[2px] bg-white text-black border-2 border-gray-300 cursor-pointer"
                        >
                            {imgUrl.imageOne ? (
                                <img
                                    src={imgUrl.imageOne}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', maxHeight: '280px' }}
                                />
                            ) : (
                                <>
                                    <p className="text-[9px] text-gray-500">
                                        supports: PNG, JPG, JPEG
                                    </p>
                                    <img src={img} alt="" />
                                    <p className="text-[9px] text-gray-500">
                                        Click here or drag file to this area to upload
                                    </p>
                                </>
                            )}
                            <input
                                type="file"
                                onChange={(e) => handleImageChange(e, 'imageOne')}
                                style={{ display: 'none' }}
                                ref={fileInputRefOne}
                            />
                        </div>

                        {/* Link */}
                        <div className="mb-6">
                            <label className="block mb-2 text-base font-semibold">Link</label>
                            <input value={link?.linkOne}
                                onChange={(e) =>
                                    setLink((prev) => ({ ...prev, linkOne: e.target.value }))
                                }
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        {/* Image Alt */}
                        <div className="mb-6">
                            <label className="block mb-2 text-base font-semibold">Image1 Alt Text</label>
                            <input value={alt?.altOne}
                                onChange={(e) =>
                                    setAlt((prev) => ({ ...prev, altOne: e.target.value }))
                                }
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    </div>

                    <div className="w-[45%]">
                        <label className="block text-base font-semibold">Advertisement Two (Center Side)</label>
                        <div
                            onClick={() => {
                                handleButtonClick(fileInputRefTwo)
                                setImageRatio(11 / 6)
                            }}
                            className="h-[280px] w-full my-8 flex flex-col justify-center items-center rounded-[2px] bg-white text-black border-2 border-gray-300 cursor-pointer"
                        >
                            {imgUrl.imageTwo ? (
                                <img
                                    src={imgUrl.imageTwo}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', maxHeight: '280px' }}
                                />
                            ) : (
                                <>
                                    <p className="text-[9px] text-gray-500">
                                        supports: PNG, JPG, JPEG
                                    </p>
                                    <img src={img} alt="" />
                                    <p className="text-[9px] text-gray-500">
                                        Click here or drag file to this area to upload
                                    </p>
                                </>
                            )}
                            <input
                                type="file"
                                onChange={(e) => handleImageChange(e, 'imageTwo')}
                                style={{ display: 'none' }}
                                ref={fileInputRefTwo}
                            />
                        </div>

                        {/* Link */}
                        <div className="mb-6">
                            <label className="block mb-2 text-base font-semibold">Link</label>
                            <input value={link?.linkTwo}
                                onChange={(e) =>
                                    setLink((prev) => ({ ...prev, linkTwo: e.target.value }))
                                }
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        {/* Image Alt */}
                        <div className="mb-6">
                            <label className="block mb-2 text-base font-semibold">Image2 Alt Text</label>
                            <input value={alt?.altTwo}
                                onChange={(e) =>
                                    setAlt((prev) => ({ ...prev, altTwo: e.target.value }))
                                }
                                type="text"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;