export const getAge = date => {
    const birthDate = new Date(date);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    //check if birthday has occured this year

    if(today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()))
    {
        age --;
    }
    return age;
}

export function convert24HourTo12Hour(time24) {   // 14:30
    let [hours, minutes] = time24.split(':');
      hours = parseInt(hours, 10);
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    return `${hours}:${minutes} ${period}`;  // 02:30 PM
  }
  
export function convert12HourTo24Hour(time12) {  // 02:30 PM
    const [time, period] = time12.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    return `${hours}:${minutes}`;  // 14:30
  }
  
  export function formatDateToDDMMYY(apiDate) {   // "2024-05-27T13:18:47.978Z"
    const date = new Date(apiDate);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${month}, ${year}`;  // 27, May, 2024
}

export function formatDateToDDMMYYInNumber(apiDate) { // 2024-05-27T13:18:47.978Z
  
  const date = new Date(apiDate);
  const utcDay = String(date.getUTCDate()).padStart(2, '0'); 
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const utcYear = date.getUTCFullYear();
  return `${utcDay}-${utcMonth}-${utcYear}`;  // 27-05-2024
}


export function calculateTotalDays(startDate, endDate) {    // 2024-05-24T00:00:00.000Z, 2024-05-31T00:00:00.000Z
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);
  if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
      throw new Error('Invalid date format');
  }
  const diffMilliseconds = endDateTime.getTime() - startDateTime.getTime();
  const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);
  return diffDays // 2
}

export function calculateTotalHours(startDate, startTime, endDate, endTime) {
  const startTime24 = convert12HourTo24Hour(startTime);
  const endTime24 = convert12HourTo24Hour(endTime);
  const startDateTimeString = `${startDate.slice(0, 10)}T${startTime24}:00.000Z`;
  const endDateTimeString = `${endDate.slice(0, 10)}T${endTime24}:00.000Z`;
  const startDateTime = new Date(startDateTimeString);
  const endDateTime = new Date(endDateTimeString);
  if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
      throw new Error('Invalid date or time format');
  }
  const diffMilliseconds = endDateTime.getTime() - startDateTime.getTime();
  const diffHours = diffMilliseconds / (1000 * 60 * 60);
  return Math.floor(diffHours);
}

export function formatToDayDateTime(dateString, timeString) {
  const date = new Date(dateString);
  const { hours, minutes } = (() => {
      const time24 = convert12HourTo24Hour(timeString);
      const [hours, minutes] = time24.split(':').map(Number);
      return { hours, minutes };
  })();
  date.setUTCHours(hours, minutes, 0, 0);
  const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC'
  };
  return date.toLocaleString('en-US', options);
}

export function dateToAge(dobString) { // '2003-01-22T00:00:00.000Z'
  const dob = new Date(dobString); 
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear(); 
  const monthDifference = today.getMonth() - dob.getMonth();
  const dayDifference = today.getDate() - dob.getDate();
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) age--
  return age;
}
