import { downArrowIcon } from 'assets/mainSidebar/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const SidebarSection = ({ title, icon, items, isActive, isParentActive, isCollapsed }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Automatically open the dropdown if any item in this section is active
        if (isParentActive) {
            setIsOpen(true);
        }
    }, [isParentActive]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <div className="relative flex flex-col">
            <div
                className="flex items-center p-4 cursor-pointer hover:bg-gray-100"
                onClick={toggleDropdown}
            >
                {/* Display only the parent icon when collapsed */}
                <img
                    src={isParentActive ? icon[0] : icon[1]}
                    alt={title}
                    className="w-6 h-6 mr-2"
                />

                {/* Only show title when sidebar is expanded */}
                {!isCollapsed && (
                    <span className={isParentActive ? 'text-brandRed' : 'text-black'}>
                        {title}
                    </span>
                )}

                {/* Conditionally render the arrow only if sidebar is not collapsed */}
                {!isCollapsed && (
                    <img
                        src={downArrowIcon}
                        alt="Toggle"
                        className={`ml-auto transition-transform duration-300 ${isOpen ? '-rotate-180' : ''}`}
                    />
                )}
            </div>

            {/* Show child items next to parent icon in collapsed state */}
            {isCollapsed && isOpen && (
                <div className="absolute left-full top-0 ml-4 flex flex-col bg-white shadow-lg z-10 w-40">
                    {items.map((item, index) => (
                        <Link
                            key={index}
                            to={item.location}
                            className={`p-4 hover:bg-gray-100 cursor-pointer ${isActive(item.name) ? 'text-brandRed' : 'text-black'}`}
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            )}

            {/* Expanded dropdown for child items when the sidebar is not collapsed */}
            {!isCollapsed && isOpen && (
                <div className="flex flex-col ml-8">
                    {items.map((item, index) => (
                        <Link
                            key={index}
                            to={item.location}
                            className={`p-4 hover:bg-gray-100 cursor-pointer ${isActive(item.name) ? 'text-brandRed' : 'text-black'}`}
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default React.memo(SidebarSection);
