import React, { memo } from 'react';

function Index({ value, onChange, type, readOnly = false, textArea = false, placeholder, height, className = '' }) {
    const Element = textArea ? 'textarea' : 'input';

    return (
        <div className={`${className}`}>
            {React.createElement(Element, {
                type: textArea ? undefined : type,  // type is only valid for input elements
                readOnly,
                value,
                placeholder,
                onChange: (e) => onChange(e.target.value),
                className: `${textArea ? 'h-full' : height ? height : 'h-[56px]'} text-base w-full px-3 flex flex-col justify-center rounded-[2px] bg-white text-black border-2 border-gray-300`
            })}
        </div>
    );
}

export default memo(Index);
