import { DropDown, InputField } from "components"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { fetchReligions, getCastesByReligion } from 'apis/religionAndCaste'
import { contactModeWithParent, couplesLookingForOptionsCouples, couplesMartialStatusOPtions, GENDER, interests, relationshipStatusOptions, singlesLookingForOptions, singlesMartialStatusOPtions } from 'shared/constants'
import { addLabelAndValueInData, formatDateToDisplayDateField } from 'utils/dataConversion'
import ImageHandler from './imageHandler'



function Index({ data, decodedId, isEdited }, ref) {


    const [religionAndCaste, setReligionAndCaste] = useState({
        religionOptions: [],
        castOptions: []
    })
    const [userData, setUserData] = useState()
    const [images, setImages] = useState([])
    const [prevImgUrl, setPrevImgUrl] = useState(null)


    const leftContent = [
        { label: "Name", value: 'name', type: "text" },
        { label: "Email", value: 'email', type: "email" },
        { label: "Phone", value: 'phone', type: "number" },
        { label: "DOB", value: 'dob', type: "date" },
        { label: "Gender", value: 'sex', mode: "", options: GENDER, type: 'dropDown' },
        { label: "Relation Ship", value: 'relationship_status', mode: "", options: relationshipStatusOptions, type: 'dropDown' },
        { label: "Martial Status", value: 'marital_status', mode: "", options: userData?.relationship_status === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions, type: 'dropDown' },
    ]

    const rightContent = [
        { label: "Looking For", value: 'looking_for', mode: "", options: userData?.relationship_status === 'single' ? singlesLookingForOptions : couplesLookingForOptionsCouples, type: 'dropDown' },
        { label: "Interest", value: 'interests', mode: "multiple", options: interests, type: 'dropDown' },
        { label: "Religion", value: 'religion_id', mode: "", options: religionAndCaste.religionOptions, type: 'dropDown' },
        { label: "Cast", value: 'caste_id', mode: "", options: religionAndCaste.castOptions, type: 'dropDown' },
        // { label: "Contact Number", value: 'contactNumber', type: "number" },
        { label: "Contact Mode", value: 'contact_mode', mode: "multiple", options: contactModeWithParent, type: 'dropDown' },

    ]

    const updateState = (key, value) => {
        isEdited()
        if (key === 'religion_id') {
            fetchCasteByReligionId(value)
        }
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    const fetchAllReligions = async () => {
        const res = await fetchReligions()
        const religion = addLabelAndValueInData(res, "name", 'id')
        setReligionAndCaste(prev => ({ ...prev, religionOptions: religion }))
    }

    const fetchCasteByReligionId = async (id) => {
        const res = await getCastesByReligion(id)
        const cast = addLabelAndValueInData(res, "name", 'id')
        setReligionAndCaste(prev => ({ ...prev, castOptions: cast }))
    }


    useEffect(() => {
        if (data?.religion_id) fetchCasteByReligionId(data?.religion_id)
        if (data) {
            setUserData(data)
            setImages(data?.images)
        }
    }, [data])

    useEffect(() => {
        fetchAllReligions()
    }, [])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='text-black bg-white'>
            <div className="m-5">
                <div className="flex justify-between">
                    {/* Left side Content */}
                    <div className="w-[46%] h-full">
                        {leftContent.map((item, index) => {
                            const useDropdown = item.type === 'dropDown';
                            return (
                                <div key={index} className="flex items-center justify-between">
                                    <h1 className='text-lg font-semibold'>{item.label}</h1>
                                    <div className={useDropdown ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl" : "w-[70%]"}>
                                        {useDropdown ? (
                                            <DropDown
                                                selectedValue={userData?.[item?.value]}
                                                placeholderValue={""}
                                                options={item.options}
                                                mode={item.mode}
                                                handleOnChange={(value) => updateState(item?.value, value)}
                                            />
                                        ) : (
                                            <InputField
                                                value={item?.type === 'date' ? formatDateToDisplayDateField(userData?.[item?.value]) : userData?.[item?.value]}
                                                onChange={(value) => updateState(item?.value, value)}
                                                type={item.type}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* Right Side Contents */}
                    <div className="w-[46%] h-full">
                        {rightContent.map((item, index) => {
                            const useDropdown = item.type === 'dropDown';
                            return (
                                <div key={index} className="flex items-center justify-between">
                                    <h1 className='text-lg font-semibold'>{item.label}</h1>
                                    <div className={useDropdown ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl" : "w-[70%]"}>
                                        {useDropdown ? (
                                            <DropDown
                                                selectedValue={userData?.[item?.value]}
                                                placeholderValue={""}
                                                options={item.options}
                                                mode={item.mode}
                                                handleOnChange={(value) => updateState(item.value, value)}
                                            />
                                        ) : (
                                            <InputField
                                                value={userData?.[item?.value]}
                                                onChange={(value) => updateState(item.value, value)}
                                                type={item.type}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}

                        {/* Image Section */}
                        <div className="flex justify-between ">
                            <h1 className='text-lg font-semibold'>Pics</h1>
                            <div className="w-[70%] h-[330px] border flex flex-col justify-center items-center">
                                <div className='flex flex-wrap items-center justify-center gap-2'>
                                    {images &&
                                        <ImageHandler imageData={images} decodedId={decodedId} handleImageUploadAndDeleteSuccess={() => fetchUserDetails()} />
                                    }
                                </div>
                            </div>

                            {prevImgUrl !== null && (
                                <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                                    <div className='relative'>
                                        <img src={prevImgUrl} alt='selectedimage' className='w-fit h-[550px]' />

                                    </div>
                                    <div className='mb-[500px] ml-[10px]'>
                                        <button onClick={() => setPrevImgUrl(null)} className="m-4 text-xl font-bold text-black">
                                            &#x2715;
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
