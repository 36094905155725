import { createNewPlanLimitation, getAllPlansLimitations, updatePlanLimitation } from 'apis/plans'
import { ManagementSecMainHeader, Table } from 'components'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { toast } from 'react-toastify'


const CreateAndEditPlanLimitations = lazy(() => import("components").then(module => ({ default: module.CreateAndEditPlanLimitations })))


function Index() {

    const [planLimitation, setPlansLimitation] = useState()
    const [selectedPlanLimitation, setSelectedPlanLimitation] = useState()
    const [showAddAndEditLimitation, setShowAddAndEditLimitation] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const fetchAllPlansLimitations = async (page) => {
        const res = await getAllPlansLimitations(page)
        setPlansLimitation(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllPlansLimitations(1)
    }, [])

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'limitation_name', maxWidth: 100 },

        { Header: 'Type', accessor: 'limitation_type' },
        {
            Header: 'Value',
            accessor: row => {
                switch (row.limitation_type) {
                    case 'unlimited':
                        return row.unlimited ? 'unlimited' : 'limited';
                    case 'access':
                        return row.access ? 'Access' : 'NoAccess';
                    default:
                        return row.limit;
                }
            }
        },
        { Header: 'Plan Category', accessor: 'plan_category.name' },
        { Header: 'Gender', accessor: 'gender' },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <span className={`text-white p-2 rounded-3xl ${value ? 'bg-green-500' : 'bg-brandRed'}`}>
                    {value ? 'active' : 'inactive'}
                </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='bg-blue-500 text-white mr-1 p-2 rounded-lg'>Edit</button>
                </div>
            )
        }

    ];


    const handleEdit = (row) => {
        setSelectedPlanLimitation(row?.original)
        setShowAddAndEditLimitation(true)
        setIsEdit(true)
    }

    const handleSubmit = async (value) => {
        const { id, ...data } = value
        let res
        if (isEdit) {
            res = await updatePlanLimitation(id, data)
        } else {
            res = await createNewPlanLimitation(data)
        }
        if (res?.success) {
            toast(`${isEdit ? 'updated' : 'Created'} Plan Limitation`)
            setShowAddAndEditLimitation(false)
            setIsEdit(false)
            fetchAllPlansLimitations(pageNation.currentPage)
        }
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedPlanLimitation('')
        setShowAddAndEditLimitation(false)
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Limitations"}
                        subHeading={"Manage your events effectively"}
                        btnText={"Create Limitation"}
                        handleBtn={() => setShowAddAndEditLimitation(true)} />
                </div>

                {/* Table */}
                {showAddAndEditLimitation ?
                    <div className="w-[98%] flex items-center justify-center">
                        <Suspense fallback={<div>loading...</div>} >
                            <CreateAndEditPlanLimitations data={selectedPlanLimitation} handleCancel={handleCancel} handleSubmit={(value) => handleSubmit(value)} />
                        </Suspense>
                    </div>
                    :
                    <div className="p-8 w-[98%]">
                        {planLimitation &&
                            <Table columns={columns} data={planLimitation} pageNation={pageNation} handlePageNation={(value) => fetchAllPlansLimitations(value)} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Index