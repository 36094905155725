import { createBlog, UpdateBlog } from 'apis/blog'
import { Steps } from 'components'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'


function Index({ saveData, onSaveSuccess, onSaveFailed, blogData, isEdit }) {
    const [data, setData] = useState({
        title: blogData?.title || null,
        sub_title: blogData?.sub_title || null
    })

    const handleSaveData = async () => {
        const toastId = toast.loading('Creating blog')
        let res
        if (isEdit) {
            const apiData = {
                current_index: 1,
                id: blogData?.id,
                title: data.title,
                sub_title: data.sub_title,
            }
            res = await UpdateBlog(apiData)
        } else {
            res = await createBlog(data)
        }
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleSaveData()
    }, [saveData])

    useEffect(() => {
        if (blogData) {
            setData({
                title: blogData?.title || '',
                sub_title: blogData?.sub_title || '',
            });
        }
    }, [blogData]);
    return (
        <div className="w-full p-6 mx-auto bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} />
                <Steps number={2} />
                <Steps number={3} />
                <Steps number={4} />
                <Steps number={5} />
                <Steps number={6} displayLine={false} />
            </div>

            {/* Meta Title */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-semibold text-gray-700">Title</label>
                <input type="text" value={data?.title}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setData(prev => ({ ...prev, title: e.target.value }))}
                />
            </div>

            {/* Meta Description */}
            <div className="mb-6">
                <label className="block mb-2 text-sm font-semibold text-gray-700">Sub Title</label>
                <input type="text" value={data?.sub_title}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setData(prev => ({ ...prev, sub_title: e.target.value }))}
                />
            </div>
        </div>
    )
}

export default Index
