import { auth } from "apis";

export const getAllAffinityActions = async (page) => {
    const res = await auth({
        method: "GET",
        url: `/affinity/actions?page=${page}&limit=10`,
    });
    return res;
};

export const getAffinityActionById = async (id) => {
    const res = await auth({
        method: "GET",
        url: `/affinity/action/${id}`,
    });
    return res;
};


export const createAffinityAction = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/affinity/action`,
        data,
    });
    return res;
};

export const updateAffinityAction = async (id, data) => {
    const res = await auth({
        method: "PUT",
        url: `/affinity/action/${id}`,
        data,
    });
    return res;
};
