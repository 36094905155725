import React, { useEffect, useState } from 'react';
import { deleteBlogById, getAllBlogs, UpdateBlog } from 'apis/blog';
import { BLOG } from 'common/routes';
import { ManagementSecMainHeader } from "components";
import Table from 'components/NEW/ui/table';
import { useNavigate } from 'react-router-dom';
import { encodeToBase64 } from 'utils/encodeAndDecode';


const Index = () => {

    const navigate = useNavigate()
    const [blogStatus, setBlogStatus] = useState({
        status: '',
        popUp: false,
        id: ''
    })
    const [blog, setBlog] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    const handleNavBtn = () => {
        navigate(BLOG.CREATE_BLOG.replace('/:id', ''))
    }

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Title', accessor: 'title' },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value, row }) => (
                <>
                    {value === 'draft' &&
                        <span onClick={() => setBlogStatus(({ popUp: true, id: row, status: value }))} className='p-2 text-red-500 bg-red-100 cursor-pointer rounded-3xl'>
                            Draft
                        </span>
                    }
                    {value === 'published' &&
                        <span onClick={() => setBlogStatus(({ popUp: true, id: row, status: value }))} className='p-2 text-green-500 bg-green-100 cursor-pointer rounded-3xl'>
                            published
                        </span>

                    }
                    {value === 'inCompleted' &&
                        <span className='p-2 text-white bg-orange-500 cursor-pointer rounded-3xl' onClick={() => handleInComplete(row)}>
                            In Completed
                        </span>

                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>View</button>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-orange-500 rounded-xl'>Edit</button>
                    <button onClick={() => handleDelete(row)} className='p-2 px-3 mr-1 text-white bg-brandRed rounded-xl'>Delete</button>
                </div>
            )
        }
    ];


    const handleStatus = async (value, row) => {
        const data = {
            id: row.original.id,
            status: value === 'draft' ? 'published' : 'draft',
        }
        const res = await UpdateBlog(data)
        if (res?.success) {
            fetchAllBlogs(pageNation?.currentPage)
            setBlogStatus((prev) => ({ ...prev, popUp: false }))
        }
    }


    const handleInComplete = (row) => {
        const id = encodeToBase64(row.original.id)
        const url = BLOG.CREATE_BLOG.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleView = (row) => {
        const id = encodeToBase64(row.original.id)
        const url = BLOG.VIEW_BLOG.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleEdit = (row) => {
        const id = encodeToBase64(row.original.id)
        const url = BLOG.CREATE_BLOG.replace(':id', id) + 'edit=true';
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleDelete = async (row) => {
        const id = row.original.id
        const res = await deleteBlogById(id)
        if (res?.success) fetchAllBlogs(pageNation.currentPage)
    };

    const fetchAllBlogs = async (page) => {
        const res = await getAllBlogs(page)
        setBlog(res?.data)
        const pagination = res?.pagination
        setPageNation({ currentPage: pagination?.current_page, nextPage: pagination?.next_page, prevPage: pagination?.previous_page })
    }

    useEffect(() => {
        fetchAllBlogs(pageNation.currentPage)
    }, [])

    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Blogs"}
                        subHeading={"Blogs"}
                        btnText={"Create Blog"}
                        handleBtn={handleNavBtn}
                    />
                </div>
                {blogStatus?.popUp &&
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="p-4 bg-white rounded-md shadow-md">
                            <p className="mb-4">Are you sure you want to proceed?</p>
                            <div className="flex items-center justify-center">
                                <button className="px-4 py-2 mr-2 bg-gray-300 rounded-md hover:bg-gray-400" onClick={(prev) => setBlogStatus({ ...prev, popUp: false })} >Cancel</button>
                                <button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600" onClick={() => handleStatus(blogStatus?.status, blogStatus?.id)}>Confirm</button>
                            </div>
                        </div>
                    </div>
                }

                {blog &&
                    <Table columns={columns} data={blog} pageNation={pageNation} handlePageNation={(value) => fetchAllBlogs(value)} />
                }

            </div>


        </div>
    );
};

export default Index;