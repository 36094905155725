import { DropDown } from "components"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import languages from "../../../../../../assets/json/languages.json"
import { addLabelAndValueInData } from '../../../../../../utils/dataConversion'



function Index({ data, isEdited }, ref) {

    const [userData, setUserData] = useState({})
    const languagesOptions = addLabelAndValueInData(languages, "name", "name")

    const content = [
        { label: "Language Known", value: 'others', options: languagesOptions, mode: 'multiple' },
        { label: "Mother Tongue ", value: 'mother_tongue', options: languagesOptions, mode: '' },
    ]


    const updateState = (key, value) => {
        isEdited()
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (data) {
            setUserData({
                others: data?.others,
                mother_tongue: data?.mother_tongue,
            })
        }
    }, [data])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Language Known</h1>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-fit">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between my-2">
                                <h1 className='text-lg font-semibold'>{item.label}</h1>
                                <div className={"h-[56px] w-[70%] flex flex-col justify-center rounded-3xl"}>
                                    <DropDown
                                        selectedValue={userData[item.value]}
                                        options={item.options}
                                        mode={item.mode}
                                        handleOnChange={(value) => updateState(item.value, value)}
                                    />

                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
