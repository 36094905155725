import { CreateNotification, ManagementSecMainHeader, Table } from "components"
import React, { Suspense, useEffect, useState } from 'react'

import { getAllUsersV2 } from 'apis/users/index.js'
import { toast } from 'react-toastify'
import { USERS } from "../../../common/routes.js"

import { UserFilter } from "components/NEW/filters"
import { sendNotifications } from "apis/notification"
import { encodeToBase64 } from "utils/encodeAndDecode.js"
import { useNavigate } from "react-router-dom"


function Index() {
    const navigate = useNavigate()
    const [showNotification, setShowNotification] = useState(false)
    const [userData, setUserData] = useState()
    const [selectedRows, setSelectedRows] = useState({})
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const columns = [
        {
            Header: () => (
                <input
                    type="checkbox"
                    checked={Object.values(selectedRows).every(Boolean)}
                    onChange={toggleSelectAll}
                />
            ),
            id: 'selection',
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={!!selectedRows[row.original.id]}
                    onChange={() => toggleRowSelection(row.original.id)}
                />
            ),
        },
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Phone', accessor: 'phone' },
        { Header: 'Category', accessor: 'personal.relationship_status' },
        { Header: 'Gender', accessor: 'personal.sex' },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>View</button>
                </div>
            )
        },
    ];

    const toggleRowSelection = (id) => {
        setSelectedRows(prev => ({
            ...prev,
            [id]: !prev[id]
        }))
    }

    const toggleSelectAll = () => {
        const allSelected = Object.values(selectedRows).every(Boolean)
        const newSelectedRows = {}
        userData.forEach(user => {
            newSelectedRows[user.id] = !allSelected
        })
        setSelectedRows(newSelectedRows)
    }



    const handleFilterData = (value) => {
        const savedFilters = JSON.parse(sessionStorage.getItem('newUserFilter'));
        fetchAllUsers(savedFilters?.page || 1, value)
    }


    const fetchAllUsers = async (page = 1, value, limit = rowsPerPage) => {
        setUserData(null)
        const savedFilters = JSON.parse(sessionStorage.getItem('newUserFilter'));
        let updatedData
        if (value) {

            updatedData = {
                ...value,
                page: page,
                limit: limit
            };
        } else if (savedFilters) {
            updatedData = {
                ...savedFilters,
                page: page,
                limit: limit
            };
        } else {
            updatedData = {
                page: page,
                limit: limit
            };
        }

        const res = await getAllUsersV2(updatedData)
        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
        sessionStorage.setItem('newUserFilter', JSON.stringify(updatedData));
    }


    useEffect(() => {
        const savedFilters = JSON.parse(sessionStorage.getItem('newUserFilter'));
        fetchAllUsers(savedFilters?.page || 1, savedFilters || null)
    }, [])


    const handleBtn = async () => {
        setShowNotification(true)
    }

    const handleView = (row) => {
        const id = encodeToBase64(row.original.id)
        navigate(USERS.EDIT_USER_DETAILS.replace(':id', id));
    };

    const handleSubmit = async (value, allUser) => {
        const selectedRowIds = Object.keys(selectedRows);
        let data
        if (allUser) {
            data = {
                ...value,
                type: "promotional",
                users: selectedRowIds || [],
                all_users: true
            }
        } else {
            if (Object.keys(selectedRows).length === 0) {
                toast.error("Return back and select at least one user or click All User")
                return
            } else {
                data = {
                    ...value,
                    type: "promotional",
                    users: selectedRowIds,
                    all_users: false
                }
            }
        }
        const res = await sendNotifications(data)
        if (res?.success) {
            setShowNotification(false)
        }
    }


    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Create Notification"}
                        subHeading={"Notification Management"}
                        btnText={"Create Notification"}
                        handleBtn={handleBtn}
                        handleFilterData={(value) => handleFilterData(value)}
                        FilterComponent={UserFilter}
                    />
                </div>
                {/* Table */}
                {showNotification ?
                    <div className="w-[98%] flex items-center justify-center">
                        <Suspense fallback={<div>loading...</div>} >
                            <CreateNotification handleCancel={() => setShowNotification(false)} handleSubmit={(value, boolean) => handleSubmit(value, boolean)} />
                        </Suspense>
                    </div>
                    :
                    <div className="p-8">
                        <div className="flex items-center">

                            <div className="flex items-center">
                                <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                    Rows per page:
                                </label>
                                <select
                                    id="rowsPerPage"
                                    name="rowsPerPage"
                                    value={rowsPerPage}
                                    onChange={(e) => {
                                        setRowsPerPage(e.target.value)
                                        fetchAllUsers(pageNation.currentPage, null, e.target.value)
                                    }}
                                    className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={500}>500</option>
                                </select>
                            </div>
                            {userData &&
                                <div className="flex items-center ms-2">
                                    <h1>Total Filtered Users</h1>
                                    <h1 className='font-semibold ms-2'>: {userData.length > 0 ? userData.length : 0}</h1>
                                </div>
                            }
                        </div>

                        {userData ?
                            <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllUsers(value)} selectedRows={selectedRows} rowsPerPage={rowsPerPage} />
                            :
                            <div className="flex items-center justify-center w-full text-2xl text-black">Loading the Data Please Wait</div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Index