import { Select } from 'antd';
import React, { memo } from 'react';

const { Option } = Select;

function Index({ label, selectedValue, placeholderValue, options, handleOnChange, mode, readOnly = false, height = false }) {

    return (
        <div className='flex flex-col justify-center w-full h-full'>
            {label &&
                <p className='mb-2 text-lg font-normal text-black'>{label}</p>
            }
            <div className={`w-full ${height ? height : 'h-[65px]'} flex justify-between items-center cursor-pointer`} >
                <Select
                    disabled={readOnly}
                    placeholder={placeholderValue}
                    value={selectedValue && selectedValue}
                    onChange={handleOnChange}
                    className='w-full h-full custom-select'
                    mode={mode}
                    showSearch={true}
                    // mode='multiple'
                    suffixIcon={
                        <svg width="15" height="8" className='me-2' viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H15L7.5 8L0 0Z" fill="#5D6577" />
                        </svg>
                    }
                >
                    {options?.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </div>
            <style>
                {`
                    .custom-select .ant-select-selector {
                        border-radius: 2px !important;
                        overflow: hidden;
                    }

                    .ant-select-dropdown {
                        border-radius: 8px !important;
                    }
                `}
            </style>
        </div>
    );
}

export default memo(Index);
