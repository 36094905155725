import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { DropDown } from "components"
import { drinkingOptions, smokingOptions, eatingOptions } from '../../../../../../shared/constants'

function Index({ data, isEdited }, ref) {
    const [userData, setUserData] = useState({})

    const content = [
        { label: "Drinking", value: 'drinking', options: drinkingOptions },
        { label: "Smoking", value: 'smoking', options: smokingOptions },
        { label: "Eating", value: 'eating', options: eatingOptions },
    ]

    const updateState = (key, value) => {
        isEdited()
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (data) {
            setUserData({
                drinking: data?.drinking || null,
                smoking: data?.smoking || null,
                eating: data?.eating || null,
            })
        }
    }, [data])

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Diet Attributes</h1>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-fit">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='text-lg font-semibold'>{item.label}</h1>
                                <div className="w-[70%]">
                                    <DropDown
                                        selectedValue={userData[item.value]}
                                        options={item.options}
                                        handleOnChange={(value) => updateState(item.value, value)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
