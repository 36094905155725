import React, { lazy, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { editEvent, getEventById } from '../../../apis/events';
import { EVENTS } from '../../../common/routes';
import { decodeFromBase64 } from '../../../utils/encodeAndDecode';


const CreateAndEditEventsFirstPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFirstPage })));
const CreateAndEditEventsFifthPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFifthPage })));



function Index() {
    const { id } = useParams();
    const navigate = useNavigate()
    const [eventData, setEventData] = useState()

    const fetchEventById = async () => {
        const decodedId = decodeFromBase64(id)
        const res = await getEventById(decodedId)
        setEventData(res.data)
    }

    useEffect(() => {
        fetchEventById()
    }, [])

    const handleNextPage = async (value) => {
        setEventData(prev => ({ ...prev, ...value }))
    }


    const handleSubmit = async () => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { sold_out, createdAt, updatedAt, id, enable_connections, ...other } = eventData;
        const structData = {
            ...other,
            categories: other?.categories?.map(item => item?._id || item).filter(item => item !== null),
            co_hosts: other?.co_hosts?.map(item => item?._id || item).filter(item => item !== null),
            tags: other?.tags?.map(item => item?._id || item).filter(item => item !== null),
            location: typeof other?.location === 'object' ? other?.location?._id : other?.location,
            host: typeof other?.host === 'object' ? other?.host?._id : other?.host
        };

        const toastId = toast.loading("Updating Event Please Wait...");
        const res = await editEvent(id, structData);
        toast.dismiss(toastId);

        if (res.success) {
            navigate(EVENTS.EVENTS)
            toast.success("Event updated Successfully...");
        }
    };




    const Pages = [CreateAndEditEventsFifthPage, CreateAndEditEventsFirstPage]

    return (
        <div className='flex flex-col h-full'>


            <div className="bg-[#f8f8f8] h-full w-full">
                <div className="w-[96%] mx-auto my-10">
                    {eventData &&
                        <>
                            {Pages.map((Page, index) => (
                                <Page key={index} nextPage={handleNextPage} data={eventData} edit={true} />
                            ))}
                        </>
                    }

                    <div className="flex justify-center">
                        <button className='p-2 px-4 text-white transition duration-150 ease-in-out bg-green-500 shadow-lg hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 rounded-3xl' onClick={handleSubmit}>Submit</button>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default Index