import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { Links } from "./Links";
import RoutAuthentication from "components/NEW/routeHandler";

export default function Routers() {
  const location = useLocation();
  const ref = useRef(null);
  useEffect(() => {
    ref.current.complete();
  }, [location]);
  return (
    <>
      <LoadingBar ref={ref} />
      <Routes>
        {Links.map((route, i) => {
          return (
            <Route key={i}
              exact
            // element={route.element}
              element={
                <RoutAuthentication
                key={i}
                // eslint-disable-next-line react/no-children-prop
                children={route.element}
                  auth={route.auth}
                  layoutType={route.layoutType} 
                  routeName={route.name}
                />}
              
              path={route.path} />
          );
        })}
      </Routes>
    </>
  )
}
