import { DropDown, InputField, RangeSliderDoublePointer } from "components";
import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { couplesLookingForOptionsCouples, couplesMartialStatusOPtions, eventMode, genderOptions, relationshipStatusOptions, singlesLookingForOptions, singlesMartialStatusOPtions } from "../../../../../shared/constants";
import { pageOneValidation } from '../../../../../utils/validations/event';

// Assets
import { getAllEventCategory, getAllEventTags, getAllHosts, getAllLocation } from 'apis/events';
import { addLabelAndValueInData } from 'utils/dataConversion';
import { getAllPlansCategory } from "apis/plans";


function Index({ nextPage, data, edit = false }) {
    const [options, setOptions] = useState({
        host: [],
        location: [],
        category: [],
        tags: [],
        plans: [],
    })

    const extractIds = (items) => {
        if (Array.isArray(items)) {
            const ids = items.map(item => item?._id);
            return ids.includes(undefined) ? items : ids;
        }
        return [];
    };

    const [eventData, setEventData] = useState({
        relationship_status: data?.relationship_status || null,
        gender: data?.gender || null,
        age_from: data?.age_from || 18,
        age_to: data?.age_to || 55,
        marital_status: data?.marital_status || [],
        host: data?.host?._id || null,
        co_hosts: extractIds(data?.co_hosts),
        mode: data?.mode || null,
        online_event_link: data?.online_event_link,
        location: data?.location?._id || null,
        categories: extractIds(data?.categories),
        tags: extractIds(data?.tags),
        looking_for: data?.looking_for,
        freemium_plans: data?.freemium_plans,
    });




    const updateState = (key, value) => {
        setEventData(prev => ({ ...prev, [key]: value }))
    }

    const handleNextPage = async () => {
        try {
            await pageOneValidation.validate(eventData, { abortEarly: false });
            localStorage.setItem('createEventData', JSON.stringify(eventData));
            nextPage(eventData, 2);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }


    const handleEdit = async () => {
        try {
            await pageOneValidation.validate(eventData, { abortEarly: false });
            nextPage(eventData)
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const fetchAllHosts = async () => {
        const res = await getAllHosts()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, host: transformedData }))
    }

    const fetchAllLocation = async () => {
        const res = await getAllLocation()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, location: transformedData }))
    }

    const fetchAllEventCategory = async () => {
        const res = await getAllEventCategory()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, category: transformedData }))
    }

    const fetchAllEventTags = async () => {
        const res = await getAllEventTags()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, tags: transformedData }))
    }

    const fetchAllPlanCategory = async () => {
        const res = await getAllPlansCategory()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, plans: transformedData }))
    }


    const contentLeftSide = [
        { type: 'dropDown', key: 'relationship_status', label: "Relationship Status", options: relationshipStatusOptions },
        { type: 'rangeSliderDoublePointer', key1: 'age_from', key2: 'age_to', label1: "Age from", label2: "Age to", range: [18, 55] },
        { type: 'dropDown', key: 'marital_status', label: "Marital status", options: eventData.relationship_status === "single" ? singlesMartialStatusOPtions : couplesMartialStatusOPtions, mode: "multiple", },
        { type: 'dropDown', key: "gender", label: "Gender", options: genderOptions },
        { type: 'dropDown', key: "categories", label: "Category", options: options?.category, mode: "multiple" },
        { type: 'dropDown', key: "looking_for", label: "LookingFor", options: [...singlesLookingForOptions, ...couplesLookingForOptionsCouples], mode: "multiple" },

    ]

    const contentRightSide = [
        { type: 'dropDown', key: 'host', label: "Host", options: options?.host, isVisible: true },
        { type: 'dropDown', key: 'co_hosts', label: "Co Hosts", options: options?.host, mode: "multiple", isVisible: true },
        { type: 'dropDown', key: 'location', label: "Location", options: options?.location, isVisible: true },
        { type: 'dropDown', key: 'mode', label: "Mode", options: eventMode, isVisible: true },
        { type: 'text', key: 'online_event_link', label: "Meeting Url", isVisible: eventData?.mode === 'online' ? true : false },
        { type: 'dropDown', key: 'tags', label: "Tags", options: options?.tags, mode: "multiple", isVisible: true },
        { type: 'dropDown', key: 'freemium_plans', label: "Plans", options: options?.plans, mode: "multiple", isVisible: true },

    ]

    useEffect(() => {
        fetchAllHosts()
        fetchAllLocation()
        fetchAllEventCategory()
        fetchAllEventTags()
        fetchAllPlanCategory()
    }, [])

    const renderInput = (item) => {
        switch (item?.type) {
            case 'dropDown':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <DropDown
                                selectedValue={eventData?.[item?.key]}
                                placeholderValue={item?.label}
                                options={item?.options}
                                mode={item?.mode}
                                handleOnChange={(value) => updateState(item?.key, value)}
                            />
                        </div>
                    </div>
                )
            case 'text':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <InputField
                                value={eventData?.[item?.key]}
                                placeholder={item?.label}
                                onChange={(value) => updateState(item?.key, value)}
                            />
                        </div>
                    </div>
                )
            case 'rangeSliderDoublePointer':
                return (
                    <div className="h-[120px] w-[400px] flex flex-col justify-center border-2 border-gray-300 rounded-3xl">
                        <p className='text-sm text-gray-500'>{`${item?.label1} ${eventData?.[item?.key1]} to ${eventData?.[item?.key2]} ${item?.label2}`}</p>
                        <RangeSliderDoublePointer
                            minMaxRange={item?.range}
                            selectedMinMaxValue={[eventData?.[item?.key1], eventData?.[item?.key2]]}
                            onRangeChange={(values) => {
                                updateState(item?.key1, values[0]);
                                updateState(item?.key2, values[1]);
                            }}
                        />
                    </div>
                )
        }
    }

    return (
        <div>
            <div className="flex items-start justify-between mt-16 mb-5">
                <h1 className='text-lg font-semibold'>What type of event are you creating</h1>

                {edit &&
                    <div className="p-1 px-4 border-2 border-gray-700 cursor-pointer h-fit"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit()
                        }}>
                        submit
                    </div>
                }
            </div>
            <div className="flex justify-between">
                <div>
                    {contentLeftSide?.map((item, index) => (
                        <div key={index} className='w-[45%]'>
                            {renderInput(item)}
                        </div>
                    ))}
                </div>
                <div>
                    {contentRightSide?.map((item, index) => (
                        item?.isVisible && (
                            <div key={index} className='w-[45%]'>
                                {renderInput(item)}
                            </div>
                        )
                    ))}
                </div>
            </div>

            {!edit &&
                <div className="flex items-center justify-center w-full">
                    <button onClick={() => handleNextPage()} className='p-2 px-4 text-white bg-brandRed rounded-2xl'>Save</button>
                </div>
            }
        </div>
    )
}

export default memo(Index)