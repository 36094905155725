import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { InputField, DropDown } from 'components';
import { decodeFromBase64 } from 'utils/encodeAndDecode';
import { AFFINITY_SCORE } from 'common/routes';
import { toast } from 'react-toastify';
import { createAffinityAction, getAffinityActionById, updateAffinityAction } from 'apis/affinityScore';
import { boolean, debitAndCredit } from 'shared/constants';

const index = () => {
    const { id } = useParams();
    const navigate = useNavigate()

    const [actionData, setActionData] = useState({
        action_name: '',
        score: 0,
        type: "debit",
        status: true
    });

    const content = [
        { type: 'text', label: 'Action Name', key: 'action_name', },
        { type: 'text', label: 'Score', key: 'score', },
        { type: 'dropDown', label: 'Type', key: 'type', options: debitAndCredit },
        { type: 'dropDown', label: 'Status', key: 'status', options: boolean },
    ];


    const updateState = (key, value) => {
        setActionData(prev => ({ ...prev, [key]: value }))
    };

    const fetchActionDataById = async (id) => {
        const res = await getAffinityActionById(decodeFromBase64(id))
        if (res?.success) setActionData(
            {
                action_name: res?.data?.action_name,
                score: res?.data?.score,
                status: res?.data?.status,
                type: res?.data?.type
            }
        )
    }

    const handleCancel = () => {
        navigate(AFFINITY_SCORE.AFFINITY_SCORE)
    }

    const handleSubmit = async () => {
        const toastId = toast.loading(`${id ? 'Updating' : 'Creating'} Affinity Action`)
        let res
        if (id) {
            res = await updateAffinityAction(decodeFromBase64(id), actionData)
        } else {
            res = await createAffinityAction(actionData)
        }
        toast.dismiss(toastId)
        if (res?.success) navigate(AFFINITY_SCORE.AFFINITY_SCORE)
    }

    useEffect(() => {
        if (id) {
            fetchActionDataById(id)
        }
    }, [])

    const renderInput = (item) => {
        switch (item?.type) {
            case 'dropDown':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <DropDown
                                selectedValue={actionData?.[item?.key]}
                                placeholderValue={item?.label}
                                options={item?.options}
                                mode={item?.mode}
                                handleOnChange={(value) => updateState(item?.key, value)}
                            />
                        </div>
                    </div>
                )
            case 'text':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <InputField
                                value={actionData?.[item?.key]}
                                placeholder={item?.label}
                                onChange={(value) => updateState(item?.key, value)}
                                className="w-[400px]"
                            />
                        </div>
                    </div>
                )
        }
    }



    return (
        <div className='w-[96%] mx-auto'>
            <div className=" w-full p-6 bg-white shadow-md rounded-md">
                <h2 className="text-xl font-bold mb-4">AFFILIATE COUPONS</h2>
                <p className="mb-2">Add time that highlight what makes it unique</p>

                <div className="w-full flex flex-wrap flex-col lg:flex-row justify-center items-start my-2 ">
                    <div>
                        {content?.map((item, index) => (
                            <div key={index} className='w-[45%]'>
                                {renderInput(item)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex w-full justify-center mt-10 gap-5">
                    <button type="button" className="px-4 py-2 border-2 border-gray-500 text-black h-[50px]" onClick={handleCancel}>Cancel</button>
                    <button type="submit" className="px-4 py-2 bg-green-500 text-white h-[50px]" onClick={handleSubmit}>{id ? 'Update' : 'Create'}</button>
                </div>
            </div>

        </div>
    )
}

export default index