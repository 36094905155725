import React from 'react';
import { Link } from 'react-router-dom';

// Assets
import facebookIcon from 'assets/socialMediaIcons/black/facebook.png'
import instagramIcon from 'assets/socialMediaIcons/black/instagram.png'
import linkedInIcon from 'assets/socialMediaIcons/black/linkedIn.png'
import twitterIcon from 'assets/socialMediaIcons/black/twitter.png'
import youTubeIcon from 'assets/socialMediaIcons/black/youtube.png'

const Index = ({ toc }) => {

    const socialMediaIcons = [
        { icon: linkedInIcon, link: 'https://www.linkedin.com/company/couplesquad/' },
        { icon: facebookIcon, link: 'https://www.facebook.com/wearecouplesquad' },
        { icon: instagramIcon, link: 'https://www.instagram.com/wearecouplesquad/' },
        { icon: youTubeIcon, link: 'https://www.youtube.com/@wearecouplesquad' },
        { icon: twitterIcon, link: 'https://twitter.com/wecouplesquad' },
    ]

    const handleScrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="max-w-xs p-4 border rounded-lg">
            {/* Social Media Icons */}
            <div className="flex mb-4 space-x-4">
                {socialMediaIcons?.map((item, index) => (
                    <Link key={index} to={item?.link} className="text-blue-700">
                        <img src={item?.icon} alt="socialMediaIcons" />
                    </Link>
                ))}
            </div>

            {/* Table of Contents */}
            <div className='mb-20 text-black toc'>
                <h3 className="font-bold">Table of Contents</h3>
                <ul className="space-y-2">
                    {toc.map((item, index) => (
                        <li
                            key={item.id}
                            className="cursor-pointer hover:underline"
                            onClick={() => handleScrollToSection(item.id)}
                        >
                            {index + 1}. {item.text}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Index;
