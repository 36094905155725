import * as Yup from 'yup';

export const eventValidation = Yup.object().shape({
    name: Yup.string().required('Event name is required'),
    short_description: Yup.string().required('Event short description is required'),
    description: Yup.string().required('Event description is required'),
    mode: Yup.string().oneOf(['offline','online']).required('Please select a valid event mode'),
    isFree: Yup.boolean().required('Please select a valid event price type'),
    price: Yup.string().optional(),
    sale_price: Yup.string().optional(),
    categories: Yup.array().of(Yup.string().length(24, 'Each Event category ID must be 24 characters long')).min(1, 'At least one Event category is required').required('Event category is required'),
    location: Yup.string().length(24).required('Event location is required'),
    gender_ratio: Yup.boolean().required('Please select a valid gender ratio option'),
    male_count: Yup.number().nullable(true).optional(),
    female_count: Yup.number().nullable(true).optional(),    
    marital_status: Yup.string().oneOf(['unmarried','married','divorced','widowed']).required('Please select a valid marital status'),
    age_from: Yup.number().required('Please enter a valid age from'),
    age_to: Yup.number().required('Please enter valid age to '),
    allocated_space: Yup.number().required('Please enter a valid allocated space'),
    date: Yup.date().required('Please select a valid event date'),
    start_time: Yup.date().required('Event start time is required'),
    end_time: Yup.date().required('Event end time is required'),
    publish_status: Yup.string().oneOf(['draft','published','cancelled']).required('Please select a valid publish status'),
    thumbnail: Yup.mixed().required('Please select a thumbnail image'),
  cover_image: Yup.mixed().required('Please select a cover image'),
  online_event_link:Yup.string().optional(),
})


// NEW Version
// Validation for create Event
// Page One
export const pageOneValidation = Yup.object().shape({
    relationship_status: Yup.string().required("Relationship status is required"),
    age_from: Yup.number().required("Age from is required").min(18, "Must be at least 18 years old"),
    age_to: Yup.number().required("Age to is required")
      .min(Yup.ref('age_from'), "Age to must be greater than age from")
      .when('age_from', (age_from, schema) => {
        return schema.test({
          test: age_to => age_to > age_from,
          message: "Age to must be greater than age from"
        });
      }),
  marital_status: Yup.array().of(Yup.string()).min(1, "At least one marital status must be selected").required("Marital status is required"),    
    
  host: Yup.string().required('Host is required'),
  co_hosts: Yup.array()
    .of(Yup.string().required('Co-host ID is required'))
    .required('Co-hosts are required')
    .min(1, 'At least one co-host is required'),
  mode: Yup.string().required('Mode is required'),
  location: Yup.mixed().required("Event Location can't empty"),
  categories:Yup.array().of(Yup.string()).min(1,"At least one Categories required"),
  tags:Yup.array().of(Yup.string()).min(1,"At least one Tags required"),
  online_event_link:Yup.string().optional(),
  freemium_plans: Yup.array().of(Yup.string()).optional(),
  looking_for: Yup.array().of(Yup.string()).optional(),
})

export const fourthPage = Yup.object().shape({
  host: Yup.string().required('Host is required'),
  co_hosts: Yup.array()
    .of(Yup.string().required('Co-host ID is required'))
    .required('Co-hosts are required')
    .min(1, 'At least one co-host is required'),
  mode: Yup.string().required('Mode is required'),
  location: Yup.mixed().required("Event Location can't empty")
});

export const eventSlugGeneration = Yup.object({
  start_date: Yup.date()
      .required('Start date is required')
      .typeError('Invalid date format'),
  title: Yup.string()
      .required('Event Title is required'),
  category: Yup.string()
      .required('Category is required'),
});

const wordLimit = (maxWords,name) => ({
  name: 'wordLimit',
  exclusive: true,
  message: `${name} be no more than ${maxWords} words`,
  test: value => value.split(/\s+/).filter(Boolean).length <= maxWords
});

// Page Five
export const fifthPage = Yup.object({
  title: Yup.string().required('Title is required').test(wordLimit(20,"Title")),
  slug: Yup.string().required('Slug is required'),
  meta_title: Yup.string().required('Meta Title is required'),
  meta_description: Yup.string().required('Meta Description is required'),
  description: Yup.string()
  .required('Description is required')
  .test('html-content-check', 'Description cannot be empty', function (value) {
    const content = value.replace(/<[^>]+>/g, '');  // Strip HTML tags
    return content.trim().length > 0;  // Check if there's any text content
  }),
  image: Yup.mixed().required('Image is required'),
  start_date: Yup.date()
  .required('Start date is required')
    .min(new Date(), 'Start date cannot be in the past'),
    end_date: Yup.date().min(Yup.ref('start_date'), 'End date cannot be before start date').required('End date is required'),
  start_time: Yup.string().required('Start time is required'),
  end_time: Yup.string().required('End time is required'),
  isFree: Yup.boolean().required('Free status is required'),
  price: Yup.number()
    .when('isFree', (isFree, schema) => {
      return isFree ? schema.notRequired() : schema.min(0, 'Price must be a positive number').required('Price is required');
    }),
  max_coin_redeemable: Yup.number()
    .when('isFree', (isFree, schema) => {
      return isFree ? schema.notRequired() : schema.min(0, 'Value must be positive').required('Max coin redeemable is required');
    }),
  price_for_couple: Yup.boolean()
    .when('isFree', (isFree, schema) => {
      return isFree ? schema.notRequired() : schema.required('Price for couple is required');
    }),
  total_slots: Yup.number().min(0, 'Total slots must be positive').required('Total slots is required'),
  gender_based_slots: Yup.boolean().required('Gender based slots field is required'),
  slots_for_men: Yup.number()
    .when('gender_based_slots', (genderBasedSlots, schema) => {
      if (genderBasedSlots === true) {
        // If gender_based_slots is true, require slots_for_men with a minimum value
        return schema.min(0, 'Slots for men must be positive').required('Slots for men is required');
      } else {
        // Otherwise, do not require slots_for_men
        return schema.notRequired();
      }
    }),
slots_for_female: Yup.number()
.when('gender_based_slots', (genderBasedSlots, schema) => {
  if (genderBasedSlots === true) {
    return schema.min(0, 'Slots for Female must be positive').required('Slots for Female is required');
  } else {
    return schema.notRequired();
  }
})
}).test({
name: 'slots-total-check',
exclusive: true,
message: 'Sum of slots for men and women should not exceed total slots',
test: function(value) {
  if (!value.gender_based_slots) {
    return true;
  }
  const total = value.total_slots;
  const men = value.slots_for_men || 0;
  const women = value.slots_for_female || 0;
  return (men + women) <= total;
},
  prebooking_enabled: Yup.boolean(),
  prebooking_from: Yup.date()
    .when('prebooking_enabled', (prebooking_enabled, schema) =>
      prebooking_enabled ? 
      schema.required('Prebooking from date is required')
            .min(new Date(), 'Prebooking from date cannot be in the past') : 
      schema.nullable()
    ),
  prebooking_to: Yup.date()
    .when('prebooking_from', (prebooking_from, schema) =>
      prebooking_from ? 
      schema.required('Prebooking to date is required')
            .min(Yup.ref('prebooking_from'), 'Prebooking to date cannot be before prebooking from date') : 
      schema.nullable()
    )
    .when('end_date', (end_date, schema) => 
      end_date ? 
      schema.max(end_date, 'Prebooking end date cannot exceed the end date') : 
      schema
    ),
  prebooking_gender_based_slots: Yup.boolean(),
  prebooking_slots_for_men: Yup.number().when('prebooking_gender_based_slots', (prebooking_gender_based_slots, schema) =>
    prebooking_gender_based_slots ? schema.min(0, 'Prebooking slots for men must be positive').required() : schema.notRequired().nullable()
  ),
  prebooking_slots_for_female: Yup.number().when('prebooking_gender_based_slots', (prebooking_gender_based_slots, schema) =>
    prebooking_gender_based_slots ? schema.min(0, 'Prebooking slots for female must be positive').required() : schema.notRequired().nullable()
  ),
  enable_comments: Yup.boolean(),
  display_attendees: Yup.boolean(),
  featured: Yup.boolean()
}).test(
  'total-gender-slots-check',
  'Sum of men and women slots must not exceed total slots',
  function(value) {
    if (value.gender_based_slots) {
      const sum = (value.slots_for_men || 0) + (value.slots_for_female || 0);
      return sum <= value.total_slots;
    }
    return true;
  }
);