import React, { useEffect, useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
// import ServerError from 'pages/error/serverError';
import { authenticationProps, LAYOUT_TYPES } from 'constant/routeHandler';
import MainLayout from 'components/NEW/layout/main';


// Error boundary to catch rendering errors
// class ErrorBoundary extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { hasError: false };
//     }

//     componentDidCatch(error, info) {
//         this.setState({ hasError: true });
//         console.error(`Error caught in route: ${this?.props?.routeName}`);
//         console.error('Error details:', error);
//         console.error('Component stack info:', info);
//     }

//     render() {
//         if (this.state.hasError) {
//             return <ServerError />;
//         }
//         return this.props.Page;
//     }
// }


// eslint-disable-next-line unused-imports/no-unused-vars
const RoutAuthentication = ({ children, auth = authenticationProps.AUTH, layoutType = LAYOUT_TYPES.MAIN, routeName }) => {
    const [authStatus, setAuthStatus] = useState(null);

    const authenticate = useCallback(async () => {
        const token = localStorage.getItem('adminSession')
        if (token === null) {
            setAuthStatus(false)
        } else {
            setAuthStatus(true)
        }
    }, []);

    useEffect(() => {
        authenticate();
    }, [authenticate]);

    // Handle loading state
    if (authStatus === null) {
        return <div>Checking authentication...</div>;
    }

    // Handle unauthenticated access for protected routes
    if (auth === authenticationProps.AUTH && !authStatus) {
        localStorage.removeItem('adminSession');
        return <Navigate to="/login" replace />;
    }

    // Handle authenticated access for unauthenticated routes
    if (auth === authenticationProps.UNAUTH && authStatus) {
        return <Navigate to="/" replace />;
    }


    const LayoutToRender = layoutType === LAYOUT_TYPES.MAIN ? MainLayout : null

    // Render the component
    return (
        <>
            {layoutType === LAYOUT_TYPES.NONE ? (
                <>{children}</>
            ) : (
                <LayoutToRender>{children}</LayoutToRender>
            )}
        </>
        // <ErrorBoundary routeName={routeName}>
        //     {layoutType === LAYOUT_TYPES.NONE ? (
        //         <>{children}</>
        //     ) : (
        //         <LayoutToRender>
        //             {children}
        //         </LayoutToRender>
        //     )}
        // </ErrorBoundary>
    );


};

export default RoutAuthentication;
