import { Switch } from 'antd';
import { DropDown } from "components";
import { City } from 'country-state-city';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { fetchReligions, getCastesByReligion } from '../../../../../../../apis/religionAndCaste';
import { couplesLookingForOptionsCouples, couplesMartialStatusOPtions, drinkingOptions, eatingOptions, educationOptions, singlesLookingForOptions, singlesMartialStatusOPtions, smokingOptions, workoutOptions } from '../../../../../../../shared/constants';
import { addLabelAndValueInData } from '../../../../../../../utils/dataConversion';


function Index({ data, userDetails }, ref) {

    const [religionAndCaste, setReligionAndCaste] = useState({
        religionOptions: [],
        castOptions: []
    })
    const [userData, setUserData] = useState()
    const [relationship_status, setRelationship_status] = useState()
    const cites = City.getCitiesOfCountry('IN')


    const updateState = (key, value) => {
        if (key === 'religion_id') {
            fetchCasteByReligionId(value)
        }
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    const fetchAllReligions = async () => {
        const res = await fetchReligions()
        const religion = addLabelAndValueInData(res, "name", 'id')
        setReligionAndCaste(prev => ({ ...prev, religionOptions: religion }))
    }


    const fetchCasteByReligionId = async (ids) => {
        let allCastOptions = [];
        for (const id of ids) {
            const res = await getCastesByReligion(id);
            if (Array.isArray(res)) {
                allCastOptions = [...allCastOptions, ...res];
            }
        }
        const castWithLabel = addLabelAndValueInData(allCastOptions, 'name', 'id')
        setReligionAndCaste(prev => ({
            ...prev,
            castOptions: castWithLabel
        }));
    }


    useEffect(() => {
        if (data) {
            const religionIds = Array.isArray(data?.religion_id) ? data.religion_id : [];
            const cast = Array.isArray(data?.caste_id) ? data.caste_id : [];

            setUserData({
                ...data,
                religion_id: religionIds,
                caste_id: cast,
            })

            fetchAllReligions()
            if (religionIds.length > 0) fetchCasteByReligionId(religionIds)
        }
    }, [data])

    useEffect(() => {
        if (userDetails?.relationship_status) setRelationship_status(userDetails?.relationship_status)
    }, [userDetails])

    const content = [
        { label: "looking For", value: 'looking_for', isDropdown: true, mode: "multiple", selected: userData?.looking_for, options: relationship_status === 'single' ? singlesLookingForOptions : couplesLookingForOptionsCouples, isVisible: true },
        { label: "Marital Status", value: 'marital_status', isDropdown: true, mode: "multiple", selected: userData?.marital_status, options: relationship_status === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions, isVisible: true },
        { label: "Eating", value: 'eating', isDropdown: true, mode: "multiple", selected: userData?.eating, options: eatingOptions, isVisible: true },
        { label: "Smoking", value: 'smoking', isDropdown: true, mode: "multiple", selected: userData?.smoking, options: smokingOptions, isVisible: true },
        { label: "Workout", value: 'workout', isDropdown: true, mode: "multiple", selected: userData?.workout, options: workoutOptions, isVisible: true },
        { label: "Drinking", value: 'drinking', isDropdown: true, mode: "multiple", selected: userData?.drinking, options: drinkingOptions, isVisible: true },
        { label: "Education", value: 'education', isDropdown: true, mode: "multiple", selected: userData?.education, options: educationOptions, isVisible: true },
        { label: "Religion", value: 'religion_id', isDropdown: true, mode: "multiple", selected: userData?.religion_id, options: religionAndCaste.religionOptions, isVisible: relationship_status === 'single' ? true : false },
        { label: "Cast", value: 'caste_id', isDropdown: true, mode: "multiple", selected: userData?.caste_id, options: religionAndCaste.castOptions, isVisible: relationship_status === 'single' ? true : false },
        { label: "City", value: 'city', isDropdown: true, mode: "", selected: userData?.city, options: useMemo(() => addLabelAndValueInData(cites, "name", "name"), [cites]), isVisible: true },

        { label: "Verified Profile", value: 'verified_profile', isDropdown: false, selected: userData?.verified_profile, isVisible: true },
        { label: "Show Everyone", value: 'show_everyone', isDropdown: false, selected: userData?.show_everyone, isVisible: relationship_status === 'single' ? false : true },
        // { label: "TimeLine", value: 'timeLine', options: timeLineOptions },
    ]

    // Use Imperative Handle to expose a function to the parent to get the current userData
    useImperativeHandle(ref, () => ({
        getData: () => userData
    }));


    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Advance Filter</h1>
                <div className="flex justify-between">
                    {/* Content Section */}

                    <div className="w-full h-[1200x]">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                {item.isVisible && (
                                    <h1 className="text-lg font-semibold">{item.label}</h1>
                                )}

                                <div className='w-[70%]'>
                                    {item.isDropdown && item.isVisible ?
                                        <DropDown
                                            selectedValue={item.selected}
                                            options={item.options}
                                            mode={item.mode}
                                            handleOnChange={(value) => updateState(item.value, value)}
                                        />
                                        :
                                        <>
                                            {item.isVisible &&
                                                <Switch checked={item.selected} onChange={() => updateState(item.value, !item.selected)} className={true ? 'bg-gray-700 my-5' : 'bg-gray-400 my-5'} />
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(Index));
